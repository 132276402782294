.benchmarking-header-triple {
	height: 1026px;
	box-shadow: 0 5px 9px rgba(0, 0, 0, 0.05);
	background-image: linear-gradient(180deg, #028294 0%, #002245 100%);
}

.benchmarking-header-content-triple {
	margin-top: -780px;
	color: #ffffff;
	font-family: 'Open Sans', sans-serif;
}

.header-data-row-triple {
	margin-top: 45px;
}

.data-col-group-triple {
	margin-top: 55px;
}

#benchmarkingHeaderPeakTriple {
	-webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    width: 70px;
    height: 70px;
    /*background-image: linear-gradient(-44deg, #1E455d 0%, #1E475e 10%);*/
    background-color: rgba(30, 70, 95, .99);
    top:986px;
    left: calc(50% - 35px);
    position: absolute;
    z-index: -1;
}

.add-button-triple {
	background: transparent;
    position: absolute;
    top: 1320px;
    left: calc(50% - 16px);
    padding: 0;
    border: 0;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.compare-text-triple {
	text-transform: uppercase;
	font-size: 10px;
	font-weight: 700;
	letter-spacing: 2px;
    position: absolute;
    top: 1000px;
    width: 70px;
    left: calc(50% - 35px);
}


.data-break-triple hr {
	width:25px;
	text-align:center;
	margin: 0 auto;
	margin-top: 5px;
	margin-bottom: 5px;
	border: 0;
	border-top: 2px solid #fff;
}

@media (max-height: 1024px) {
	.benchmarking-header-triple {
		height: 756px;
		box-shadow: 0 5px 9px rgba(0, 0, 0, 0.05);
	}

	.benchmarking-header-content-triple {
		margin-top: -630px;
		color: #ffffff;
		font-family: 'Open Sans', sans-serif;
	}

	.header-data-row-triple {
		margin-top: 50px;
	}

	.data-break-triple hr {
		width:25px;
		text-align:center;
		margin: 0 auto;
		margin-top: 5px;
		margin-bottom: 5px;
	}
	
	.data-col-group-triple {
		margin-top: 35px;
	}

	#benchmarkingHeaderPeakTriple {
		-webkit-transform: rotate(45deg);
	    -moz-transform: rotate(45deg);
	    -o-transform: rotate(45deg);
	    -ms-transform: rotate(45deg);
	    transform: rotate(45deg);
	    width: 70px;
	    height: 70px;
	    /*background-image: linear-gradient(-44deg, #1E455d 0%, #1E475e 10%);*/
	    background-color: rgba(30, 70, 95, .99);
	    top:716px;
	    left: calc(50% - 35px);
	    position: absolute;
	    z-index: -1;
	}

	.add-button-triple {
		background: transparent;
	    position: absolute;
	    top:750px;
	    left: calc(50% - 16px);
	    padding: 0;
	    border: 0;
	    -webkit-transform: rotate(45deg);
	    -moz-transform: rotate(45deg);
	    -o-transform: rotate(45deg);
	    -ms-transform: rotate(45deg);
	    transform: rotate(45deg);
	}

	.compare-text-triple {
		text-transform: uppercase;
		font-size: 10px;
		font-weight: 700;
		letter-spacing: 2px;
	    position: absolute;
	    top: 730px;
	    width: 70px;
	    left: calc(50% - 35px);
	}

}

@media (max-height: 850px) {
	.benchmarking-header-triple {
		height: 716px;
		box-shadow: 0 5px 9px rgba(0, 0, 0, 0.05);
	}

	.benchmarking-header-content-triple {
		margin-top: -600px;
		color: #ffffff;
		font-family: 'Open Sans', sans-serif;
	}

	.header-data-row-triple {
		margin-top: 40px;
	}

	.data-break-triple hr {
		width:25px;
		text-align:center;
		margin: 0 auto;
		margin-top: 5px;
		margin-bottom: 5px;
	}

	.data-col-group-triple {
		margin-top: 35px;
	}

	#benchmarkingHeaderPeakTriple {
		-webkit-transform: rotate(45deg);
	    -moz-transform: rotate(45deg);
	    -o-transform: rotate(45deg);
	    -ms-transform: rotate(45deg);
	    transform: rotate(45deg);
	    width: 70px;
	    height: 70px;
	    /*background-image: linear-gradient(-44deg, #1E455d 0%, #1E475e 10%);*/
	    background-color: rgba(30, 70, 95, .99);
	    top:676px;
	    left: calc(50% - 35px);
	    position: absolute;
	    z-index: -1;
	}

	.add-button-triple {
		background: transparent;
	    position: absolute;
	    top:710px;
	    left: calc(50% - 16px);
	    padding: 0;
	    border: 0;
	    -webkit-transform: rotate(45deg);
	    -moz-transform: rotate(45deg);
	    -o-transform: rotate(45deg);
	    -ms-transform: rotate(45deg);
	    transform: rotate(45deg);
	}

	.compare-text-triple {
		text-transform: uppercase;
		font-size: 10px;
		font-weight: 700;
		letter-spacing: 2px;
	    position: absolute;
	    top: 690px;
	    width: 70px;
	    left: calc(50% - 35px);
	}

}



.header-data-label-triple {
	text-transform: uppercase;
	font-size: 11px;
	font-weight: 700;
	letter-spacing: 2px;
	padding-bottom: 4px;
}

.header-data-value-triple {
	margin-top: -2px;
	font-weight: bolder;
	font-size: 27px;
	text-transform: uppercase;
}

@media (max-width: 600px) {
	.header-data-label-triple {
		text-transform: uppercase;
		font-size: 10px;
		font-weight: 700;
		letter-spacing: 2px;
		padding-bottom: 4px;
	}

	.header-data-value-triple {
		margin-top: -2px;
		font-weight: bolder;
		font-size: 24px;
		text-transform: uppercase;
	}
}

@media (max-width: 380px) {
	.header-data-label-triple {
		text-transform: uppercase;
		font-size: 9px;
		font-weight: 600;
		letter-spacing: 2px;
		padding-bottom: 4px;
	}

	.header-data-value-triple {
		margin-top: -2px;
		font-weight: bolder;
		font-size: 23px;
		text-transform: uppercase;
	}
}

.export-button-container-triple {
	position: relative;
	height: 50px;
	padding: 0;
	margin: 0 auto;
}

.export-button-triple {
	width: 161px;
	height: 45px;
	border-radius: 3px;
	border: 2px solid #fff;
	text-transform: uppercase;
	font-size: 12px;
	font-weight: 900;
	letter-spacing: 2px;
	line-height: 22px;
	background-color:transparent;
	margin-top: 50px;
}

.mid-section {
	border-right: 2px dashed rgba(255, 255, 255, 0.15);
	border-left: 2px dashed rgba(255, 255, 255, 0.15);
}

.flag-group-triple-container {
	position: relative;
	height: 50px;
	width: 100%;
}
.flag-group-triple {
	position: absolute !important;
	width: 100%;
}
.header-flag-icon-triple {
	font-size: 40px;
	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.23);
	/*border: 2px solid #fff;*/
	border-radius: 1px;
	height: 42px;
	width: 54px !important;
}
.header-close-country-triple {
	background-color: transparent;
	border: none;
	font-size: 28px;
	margin-left: 6px;
	top: -4px;
	position: absolute;
	padding: 0;
}


.country-group-triple-container {
	position: relative;
	height: 80px;
	width: 100%;
}
.country-group-triple {
	position: absolute !important;
	width: 100%;
	height: inherit;
}
.header-country-name-triple {
	text-transform: uppercase;
	font-size: 14px;
	color: #ffffff;
	line-height: 22px;
	font-weight: 700;
	letter-spacing: 2.55px;
	text-align: center;
	width: 100%;
	overflow: hidden;
	text-overflow: ellipsis;
	margin-top: 20px;
	font-family: 'Andes',Helvetica, Arial, Verdana, Tahoma, sans-serif;
}
@media (max-width: 700px) {
	.header-country-name-triple {
		text-transform: uppercase;
		font-size: 14px;
		color: #ffffff;
		line-height: 22px;
		font-weight: 700;
		letter-spacing: 2.5px;
		text-align: center;
		width: 100%;
		overflow: hidden;
		text-overflow: ellipsis;
		margin-top: 6px !important;
	}
}
@media (max-width: 650px) {
	.header-country-name-triple {
		text-transform: uppercase;
		font-size: 14px;
		color: #ffffff;
		line-height: 20px;
		font-weight: 700;
		letter-spacing: 2.5px;
		text-align: center;
		width: 100%;
		overflow: hidden;
		text-overflow: ellipsis;
		margin-top: -8px !important;
	}
}
@media (max-width: 520px) {
	.header-country-name-triple {
		text-transform: uppercase;
		font-size: 13px;
		color: #ffffff;
		line-height: 18px;
		font-weight: 700;
		letter-spacing: 2.5px;
		text-align: center;
		width: 100%;
		overflow: hidden;
		text-overflow: ellipsis;
		margin-top: -8px !important;
	}
}

.header-change-country-triple {
	background-color: transparent;
	border: none;
	font-size: 14px;
	margin-left: 5px;
	position: absolute;
	padding: 0;
}

.export-icon-triple {
	margin-top: -3px;
}

.bottom-border-line-container-triple {
	position: relative;
	height: 5px;
	width: 100%;
}
.bottom-border-line-triple {
	width: 80%;
	margin-left: 10%;
	height: inherit;
	border-bottom: 2px dashed rgba(255, 255, 255, 0.15);
	position: absolute;
}