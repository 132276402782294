.evidence-page {
	/*width: 80% !important;*/
	/*margin-left: 10% !important;*/
	margin: auto;
}
#evidence-summary {
	color: #333333;
	font-size: 18px;
	font-weight: 400;
	line-height: 30px;
	text-align: left;
}

#evidence-summary > div {
	padding-bottom: 20px;
}

.banner {
  background-image: url('./img/temp-banner.png');
}

.evidence-image {
	margin-top: 40px;
	width: 100%;
	height: 100%;
}

.impact-tab-section {
	padding-bottom: 50px;
}

#evidence-tabs {
	color: #333333;
	font-size: 16px;
	font-weight: 400;
	line-height: 28px;
	text-align: left;
}

#evidence-tabs > .tab-content {
	background: white;
	padding: 0px 10px 10px 10px;
	border: 0px;
}

#evidence-tabs > .tab-content > .active {
	display: inline-block;
	width: 100%;
}

#evidence-tabs > .nav-tabs {
	border-bottom: 0px;
}

#evidence-tabs > .nav-tabs > li {
	border-top: 4px solid transparent;
}

#evidence-tabs > .nav-tabs > li > a {
	border-radius: 0px;
	margin-right: 0px !important;
	border: 0px;
}

#evidence-tabs > .nav-tabs > li.active {
	border-top: 4px solid #ec553a;
}

#evidence-tabs-tab-0, #evidence-tabs-tab-1, #evidence-tabs-tab-2 { 
	color: #333;
}

.aidgrade-color-bg {
	/*margin: 10px 10% !important;*/
	background: #76b20d;
	color: #333 !important;
	padding: 20px 30px !important;
	margin: 10px 0px;
}

.aidgrade-color-bg > a > img {
	width: 100%;
}

.aidgrade-num {
	padding: 10px 15px;
	background-color: rgba(186, 222, 126, .8);
	border-radius: 100px;
	line-height: 60px;
	margin-right: 5px;
	font-weight: 600;
}

.aidgrade-drop > .dropdown {
	width: 100%;
	margin: 14px 0px;
}

#aidgrade-dropdown-1, #aidgrade-dropdown-2 {
	width: 100%;
	text-align: left;
	white-space: nowrap;
	overflow: hidden;
	-o-text-overflow: ellipsis;
	text-overflow: ellipsis;
	/*opacity: 1 !important;*/
	/*cursor: pointer !important;*/
}

#aidgrade-dropdown-1 > .caret, #aidgrade-dropdown-2 > .caret {
	display: none !important;
}

.aidgrade-check-title, .aidgrade-check-text {
	font-size: 14px;
}

.aidgrade-check-text > label {
	/*cursor: pointer !important;*/
}

.aidgrade-check-text > label > input {
	margin-top: 8px !important;
}

.aidgrade-link {
	text-align: left;
	
	margin: 0px 20px !important;
	padding: 0px 10px;
	padding-top: 10px;
}

.aidgrade-button {
	background-color: rgba(186, 222, 126, .8);
	color: #333 !important;
	font-weight: 600 !important;
	margin-top: 20px;
	border-radius: 0px !important;
}

.gdi {
	/*margin: 0px 20px !important;*/
	padding: 20px 30px;
}

.gdi > a {
	color: #135488 !important;
	font-weight: 600;
}

.gdi-color-bg {
	background-image: linear-gradient(196deg, #8ecceb 0%, #6ab1dc 100%);
	/*margin: 10px 15% !important;*/
	color: #333;
	padding-top: 30px;
	padding-bottom: 50px;
}

.gdi-drop-section {
	padding-top: 20px;
	margin: 0px !important;
}

.gdi-drop.special > .dropdown > .dropdown-menu, .impact-eval-drop.special > .dropdown > .dropdown-menu {
	height: 100px;
	overflow-y: scroll;
}

.gdi-drop > .dropdown {
	width: 100%;
	margin: 14px 0px;
}

#gdi-dropdown, #gdi-dropdown-country, #gdi-dropdown-org {
	width: 100%;
	text-align: left;
}

#gdi-dropdown-country, #gdi-dropdown-org {
	opacity: 1 !important;
}

#gdi-dropdown > .caret {
	float: right;
	margin-top: 8px;
}

#gdi-dropdown-country > .caret, #gdi-dropdown-org > .caret {
	display: none !important;
}

.gdi-num {
	padding: 10px 15px;
	background-color: #fea035;
	border-radius: 100px;
	line-height: 60px;
	margin-right: 5px;
	font-weight: 600;
}

.gdi-button {
	background-color: #1a74bb;
	color: #FFFFFF;
	margin-top: 14px;
	width: 100%;
	font-size: 12px !important;
	border-radius: 0px !important;
}

#gdi-selected {
	padding-left: 20px;
	font-size: 14px;
}

.gdi-link {
	text-align: left;
	
	margin: 0px 20px !important;
	padding: 20px 10px;
	padding-top: 10px;
}

#gdi-logo {
	width: 300px;
	padding-top: 10px;
}

.impact-selector {
	/*margin: 0px 20px !important;*/
	padding: 30px 30px 20px 30px;
	font-weight: 600;
}

.impact-selector-switch {
	margin-left: 10px;
	padding: 5px 10px;
	color: #0071bc;
}

.impact-selector-switch.active-switch {
	color: #fff;
	background-color: #cd2c0f;
	padding: 5px 10px;
	border-radius: 25px;
}

.impact-selector-switch:hover {
	cursor: pointer;
}

.impact-eval {
	/*margin: 0px 20px !important;*/
	padding: 20px 30px;
}

.impact-eval-color-bg {
	background-image: linear-gradient(191deg, #028294 0%, #002245 100%);
	margin: 10px 0px;
	color: white;
	padding-top: 30px;
	padding-bottom: 50px;
}

.impact-eval-drop-section {
	padding-top: 20px;
	margin: 0px !important;
}

.impact-eval-drop > .dropdown {
	width: 100%;
	margin: 14px 0px;
}

#impact-eval-dropdown-sector, #impact-eval-dropdown-policy, #impact-eval-dropdown-mechanism, #impact-eval-dropdown-outcome {
	width: 100%;
	text-align: left;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

#impact-eval-dropdown-sector > .caret, #impact-eval-dropdown-policy > .caret, #impact-eval-dropdown-mechanism > .caret, #impact-eval-dropdown-outcome > .caret {
	margin-top: 8px;
	position: absolute;
	right: 5px;
}

.impact-eval-num {
	padding: 10px 15px;
	background-color: #fff;
	border-radius: 100px;
	line-height: 60px;
	color: #cd2c0f;
	margin-right: 5px;
	font-weight: 600;
}

.impact-eval-button {
	background-color: #fff;
	color: #cd2c0f;
	margin-top: 5px;
	width: 100%;
	font-size: 12px !important;
	font-weight: 700 !important;
	text-transform: uppercase;
	border-radius: 0px !important;
}

.impact-eval-button:hover {
	color: #cd2c0f !important;
}

#impact-eval-selected {
	padding-left: 20px;
	font-size: 14px;
}

.impact-eval-link {
	text-align: right;
	padding-right: 20px;
}

.impact-eval-check-section {
	font-size: 12px;
	line-height: 20px;
	padding-top: 10px;
}

.impact-eval-check-title {
	padding-right: 15px;
	text-transform: uppercase;
}

.impact-eval-check-text {
	padding: 0px 5px;
	margin-top: 0px !important;
}

.impact-eval-check-text:hover {
	cursor: pointer;
}

.impact-eval-error-section {
	font-size: 12px;
	margin: 10px 0px 0px 0px !important;
}

.impact-eval-display {
	margin: 10px 5% !important;
	font-size: 14px;
	border-bottom: 1px solid #ccc;
	padding-bottom: 15px;
}

.impact-eval-display > div > div > a {
	color: #cd2c0f !important;
	font-size: 18px;
	font-weight: 600;
}

.impact-outcome-1, .impact-outcome-2, .impact-outcome-3 {
	color: #fff;
	border-radius: 5px;
	padding: 10px;
	height: 100px;
	text-transform: uppercase;
	font-weight: 600;
}

.impact-outcome-1 > div > img, .impact-outcome-2 > div > img {
	padding-top: 25px;
}

.impact-outcome-3 > div > img {
	padding-top: 35px;
}

.impact-outcome-img {
	text-align: center;
}

.impact-eval-outcome-label {
	padding-top: 15px;
}

.impact-eval-outcome-assessment {
	font-size: 18px;
}

.impact-outcome-1 {
	background: #288462;
}

.impact-outcome-2 {
	background: #e6201c;
}

.impact-outcome-3 {
	background: #028294;
}

.impact-eval-assessment > span {
	font-weight: 600;
}

.impact-eval-noresults {
	font-size: 14px;
	font-weight: 600;
	text-align: center;
}

.impact-eval-reset > div > .impact-eval-button {
	width: 100px;
	margin-left: 15px;
}

@media (min-width: 768px) { 
	.impact-eval-check-section {
		display: flex;
	}
}

.impact-eval-section-embed, .gdi-section-embed {
	color: #333333;
	font-size: 16px;
	font-weight: 400;
	line-height: 28px;
	text-align: left;
}