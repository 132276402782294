.sectionWrapper {
  box-shadow: 0 5px 9px rgba(0, 0, 0, 0.05);
  background-image: linear-gradient(180deg, #028294 0%, #002245 100%);
  padding: 40px 24px;
  width: 100%;
}

.wrapper {
  max-width: 960px;
  margin: 0 auto;
}

.title {
  color: white;
  font-weight: bold;
  margin-bottom: 32px;
}

.countriesWrapper {
  display: flex;
  justify-content: space-around;
  align-items: stretch;
}

.flag {
  width: 120px !important;
  height: 120px;
  display: block;
  position: relative;
}

.countryName {
  color: white;
  margin-bottom: 24px;
  font-weight: bold;
  font-family: 'Andes';
}

.label {
  color: white;
  text-transform: uppercase;
  /* font-weight: bold; */
}

.value {
  color: white;
  font-size: 40px;
  font-family: 'Andes';
  font-weight: bold;
}

.button {
  background-color: transparent;
  color: white;
  border: 1px solid white;
  margin-top: 24px;
  margin-bottom: 40px;
}

.button:hover {
  background-color: #028294;
  border-color: #028294;
}

.removeCountryButton {
  position: absolute;
  top: 0;
  right: -48px;
  background: none;
  border: none;
  padding: 24px;
}

.row {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 24px 0;
  width: 100%;
}

@media screen and (max-width: 768px) {
  .countriesWrapper {
    flex-direction: column;
  }
}
