.modalBodyWrapper {
  padding: 24px;
}

.tableWrapper {
  width: 100%;
}

.tableRow {
  display: grid;
  gap: 24px;
  grid-template-columns: 2fr 4fr 2fr 2fr 2fr 2fr 1fr;
  padding: 12px;
}

.headerRow {
  background-color: #002244;
  color: white;
}

.tableHeadItem {
  font-family: 'Andes';
  font-weight: bold;
}

.rowA {
  background-color: #cde7f9;
}

.rowB {
  background-color: #a3daff;
}

.totalRow {
  background-color: #00538a;
  color: white;
  font-family: 'Andes';
  font-weight: bold;
}
