.chart-gauge {

} 
.chart-color1 {
	fill: #be3845;
}
.chart-color2 {
	fill: #f38500;
}
.chart-color3 {
	fill: #9ec3e2;
}
.chart-bg {
	/*fill: rgba(32,42,62,0.6);*/
	/*fill: rgb(35,58,85);*/
}
.gauge-tic {
/*  fill: #414142;*/
/*  fill: rgb(49,15,18); /*red*/
/*	fill: rgb(40,50,58); /*blue*/
/*  fill: rgb(60,35,5); /*yellow*/
	fill: rgb(35,58,85);
}
.needle, .needle-center {
	fill: var(--color-primary);
	stroke: #fff;
    stroke-width: .3em;
}
.needle-center-center {
	fill: var(--color-primary);
}

.gauge-svg-text {
	fill: #333;
    font-family: 'Open Sans', Helvetica, Arial, Verdana, Tahoma, sans-serif;
    font-size: 12px;
    font-weight: bold;
}

.gauge-svg-text-large {
	fill: #333;
    font-family: 'Open Sans', Helvetica, Arial, Verdana, Tahoma, sans-serif;
    font-size: 15px;
    font-weight: bold;
}