.login-wrapper {
    width: 100%;
    margin-top: 90px;
    height: calc(100vh - 90px);
    display: flex;
    flex-direction: row;
    padding: 6.625rem 5.469rem;
    background-repeat: no-repeat;
    background-size: cover;
    align-items: center;
    justify-content: space-between;
}

.login-form {
    margin-top: 0;
    padding: 40px;
    background: white;
    border-radius: 2px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 530px;
    height: 467px;
}

.login-title {
    color: #262626;
    font-size: 22px;
    text-align: center;
    margin: 0 0 2rem;
}

.login-control {
    display: flex;
    margin-bottom: 1.3rem;
    flex-direction: column;
    align-items: flex-start;
}

.login-control label {
    color: #1A1A1A;
    font-weight: 400;
}

.login-input {
    font-size: 16px !important;
    border: 1px solid #666666 !important;
    padding: 12px 16px !important;
}

.login-input::placeholder {
    color: #b4b4b4 !important;
}