.groupedbar-custom-legend {
	display: flex;
	justify-content: center;
}
.groupedbar-legend-option {
	width: 20px ;
	height: 20px;
	margin-left: 10px; 
	margin-right: 10px;
}
.groupedbar-legend-option-thin {
	width: 20px;
	height: 5px;
	margin-left: 10px;
	margin-right: 10px;
	margin-top: 7.5px;
}
.groupedbar-legend-div {
	display: flex;
	margin-right: 15px;
}
.groupedbar-legend-div:hover {
	cursor: pointer;
}
.groupedbar-legend-div.transparent > .groupedbar-legend-option, .groupedbar-legend-div.transparent > .groupedbar-legend-text {
	opacity: 0.3;
}
.groupedbar-covid-legend-div {
	display: flex;
	margin-right: 15px;	
	min-height: 35px;
	max-height: 60px;
}

.groupedbar-legend-text {
	font-size: 14px !important;
}