 .barGraphGrid > .tick line {
  stroke: #333333;
  stroke-opacity: 0.5;
  shape-rendering: crispEdges;
  stroke-width: 3px;
  transform: translate(10px);
}
.barGraphGrid > .tick ~ .tick line {
  stroke: #333333;
  stroke-opacity: 0.2;
  shape-rendering: crispEdges;
  stroke-width: 3px;
  transform: translate(10px);
}

.barGraphGrid path {
  stroke-width: 0;
}

.barGraph text {
	  fill: #333333;
    font-family: 'Open Sans', sans-serif;
    font-size: 2em;
}
.barGraph text.indicator-legend-text {
    font-size: 1.25em;
    font-weight: 700;
}
.barGraph2 text {
    fill: #333333;
    font-family: 'Open Sans', sans-serif;
    font-size: 1.8em;
}
.barGraph2 text.indicator-legend-text {
    font-size: 1.05em;
    font-weight: 700;
}
.barGraph3 text {
    fill: #333333;
    font-family: 'Open Sans', sans-serif;
    font-size: 1.6em;
}
.barGraph3 text.indicator-legend-text {
    font-size: .85em;
    font-weight: 700;
}
.barGraph4 text {
    fill: #333333;
    font-family: 'Open Sans', sans-serif;
    font-size: 1.4em;
}
.barGraph4 text.indicator-legend-text {
    font-size: .65em;
    font-weight: 700;
}
.barGraph5 text {
    fill: #333333;
    font-family: 'Open Sans', sans-serif;
    font-size: 1.2em;
}
.barGraph5 text.indicator-legend-text {
    font-size: .6em;
    font-weight: 700;
}
.barGraph6 text {
    fill: #333333;
    font-family: 'Open Sans', sans-serif;
    font-size: 1em;
}
.barGraph6 text.indicator-legend-text {
    font-size: .6em;
    font-weight: 700;
}
.barGraph7 text {
    fill: #333333;
    font-family: 'Open Sans', sans-serif;
    font-size: 0.8em;
}
.barGraph7 text.indicator-legend-text {
    font-size: .6em;
    font-weight: 700;
}

.barGraphAxis path {
	display: none;
}

.barGraphAxis .tick line {
	display: none;
}
.barGraphTargetText {
	font-size: 1.5em !important;
  font-weight: 700;
}
.barGraphTargetText2 {
  font-size: 1.4em !important;
  font-weight: 700;
}
.barGraphTargetText3 {
  font-size: 1.3em !important;
  font-weight: 700;
}
.barGraphTargetText4 {
  font-size: 1.2em !important;
  font-weight: 700;
}
.barGraphTargetText5 {
  font-size: 1.1em !important;
  font-weight: 700;
}
.barGraphTargetText6 {
  font-size: 1em !important;
  font-weight: 700;
}
.barGraphTargetText7 {
  font-size: 0.9em !important;
  font-weight: 700;
}