/*.modal-add-indicator {*/
/*  z-index: 1000;*/
/*  position: fixed;*/
/*  top: calc(50% - 225px);*/
/*  left: calc(50% - 580px);*/
/*  width: 1160px;*/
/*  height: 400px;*/
/*  background-color: rgba(0, 0, 0, 0.5);*/
/*  display: flex;*/
/*  flex-direction: column;*/
/*  justify-content: center;*/
/*  align-items: center;*/
/*  background-color: #fff;*/
/*  padding: 48px;*/
/*}*/

.modal-add-indicator .modal-header-add-indicator {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 10px;
  width: 100%;
}

.modal-add-indicator .modal-header-add-indicator h2 {
  margin: 0;
  font-size: 28px !important;
  font-weight: 400 !important;
  font-family: 'Open Sans' !important;
}

.close-button {
  background-color: transparent;
  border: none;
  font-size: 20px;
  cursor: pointer;
}

.modal-add-indicator .modal-body {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  height: 100%;
  width: 100%;
}

.modal-add-indicator .select-row {
  display: flex !important;
  flex-direction: column !important;
  align-items: start !important;
  justify-content: start !important;
  margin-bottom: 10px !important;
}

.modal-add-indicator .select-row label {
  margin-right: 10px;
  display: block !important;
  margin-left: 0 !important;
  margin-bottom: 10px !important;
}

.modal-add-indicator .select-row select {
  border-radius: 0.1px;
  padding: 0px 16px;
  font-size: 16px;
  border: 1px solid #666666;
  filter: drop-shadow(0px 0px 0px #CBDAFC);
  width: 236px;
  height: 46px;
}

.modal-add-indicator .select-row select > option {
  width: 236px;
  max-width: 90%;
  white-space: nowrap;
  text-overflow: ellipsis;
  -webkit-appearance: none;
}

.modal-add-indicator .select-row input[type="number"] {
  padding: 0px 16px;
  font-size: 16px;
  border-radius: 0.1px;
  border: 1px solid #666666;
  filter: drop-shadow(0px 0px 0px #CBDAFC);
  width: 236px;
  height: 46px;
}

.modal-add-indicator > .modal-footer-add-indicator {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  width: 100%;
  padding: 15px;
}

.modal-add-indicator .cancel-button {
  padding: 5px 10px;
  font-size: 16px;
  border: 1px solid #009FDA;
  background-color: transparent;
  cursor: pointer;
  width: 122px;
  height: 49px;
  color: #009FDA;
  margin-left: 2px;
}

.modal-add-indicator .add-indicator-button {
  padding: 5px 10px;
  font-size: 16px;
  border: none;
  background-color: #009FDA;
  color: #fff;
  cursor: pointer;
  width: 212px;
  height: 49px;
  margin-right: 2px;
}
