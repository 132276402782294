@font-face { font-family: 'Andes'; src: url('./andes-font/Andes-Regular.otf'); } 
/* @font-face { font-family: 'Andes'; font-weight: lighter; src: url('./andes-font/Andes-ExtraLight.otf'); } */
@font-face { font-family: 'Andes'; font-weight: bold; src: url('./andes-font/Andes-Bold.otf'); }

html, body {
	min-height: 100%;
	height: 100%;
	font-family: 'Open Sans', sans-serif;
	font-size: 16px !important;
}

.container-fluid {
	/*background: #f6f6f6;*/
	background-image: linear-gradient(180deg, #f0f0f0 0%, #ffffff 100%);
}

.categories h1 {
	color: #333333;
	font-family: 'Andes',Helvetica, Arial, Verdana, Tahoma, sans-serif;
	font-size: 32px;
	font-weight: 700;
	/*letter-spacing: -0.26px;*/
	line-height: 39px;
}

.categories h2 {
	color: #333333;
	font-family: 'Andes',Helvetica, Arial, Verdana, Tahoma, sans-serif;
	font-size: 23px;
	font-weight: 700;
	/*letter-spacing: -0.19px;*/
	line-height: 32px;
}

.categories h3 {
	color: #333333;
	font-family: 'Andes',Helvetica, Arial, Verdana, Tahoma, sans-serif;
	font-size: 18px;
	font-weight: 700;
	/*letter-spacing: -0.15px;*/
	line-height: 25px;
}

.categories h4 {
	color: #333333;
	font-family: 'Andes',Helvetica, Arial, Verdana, Tahoma, sans-serif;
	font-size: 14px;
	font-weight: 700;
	/*letter-spacing: 1.75px;*/
	line-height: 19px;
	text-transform: uppercase;
}

.categories h5 {
	color: #333333;
	font-family: 'Andes',Helvetica, Arial, Verdana, Tahoma, sans-serif;
	font-size: 12px;
	font-weight: 700;
	/*letter-spacing: 1.75px;*/
	line-height: 19px;
	text-transform: uppercase;
}

.categories body {
	color: #333333;
	font-family: 'Open Sans', sans-serif;
	font-size: 15px;
	font-weight: 400;
	letter-spacing: -0.13px;
	margin: 0;
  	padding: 0;
}

.categories .footnote {
	color: #333333;
	font-family: 'Open Sans', sans-serif;
	font-size: 11px;
	font-weight: 400;
	line-height: 19px;
}

.unpadify {
	margin: 0 !important;
	padding: 0 !important;
}

#help-button > a {
	color: #333333 !important;
}

#help-button > a:hover {
	text-decoration: none;
}


.customTabs > .nav-tabs { 
	border: none; 
	border: 1px solid transparent !important; 
}
.customTabs > .nav-tabs > li.active > a, .customTabs > .nav-tabs > li.active > a:focus, .customTabs > .nav-tabs > li.active > a:hover { 
	border: none; 
	border-width: 0; 
}
.customTabs > .nav-tabs > li > a { 
	border: none; 
	color: #fff; 
}
.customTabs > .nav > li > a {
	/*padding: 5px 30px !important;*/
	padding-left: 0px !important;
}
.customTabs > .nav-tabs > li.active > a, .customTabs > .nav-tabs > li > a:hover { 
	border: none; 
	color: #26bcbe !important; 
	background: transparent; 
}
.customTabs > .nav-tabs > li > a::after { 
	border: none; 
	content: ""; 
	background: #26bcbe; 
	height: 3px; 
	position: absolute; 
	width: 29px; 
	bottom: -2px; 
	transition: all 250ms ease 0s; 
	transform: scale(0); 
}
.customTabs > .nav-tabs > li.active > a::after, .customTabs > .nav-tabs > li:hover > a::after { 
	border: none;
	transform: scale(1); 
}
.customTabs > .nav-tabs > li.active > a, .customTabs > .nav-tabs > li.active > a:hover, .customTabs > .nav-tabs > li.active > a:focus {
	border: 1px solid transparent !important; 
}
.customTabs > .nav > li > a:hover, .customTabs > .nav > li > a:focus {
	background-color: transparent !important; 
}
.customTabs > .tab-nav > li > a::after { 
	border: none; 
	background: #26bcbe none repeat scroll 0% 0%; 
	color: #fff; 
}
.customTabs > .tab-pane { 
	border: none; 
	margin: 0;
  	padding: 0;
}
.customTabs > .tab-content{
	border: none; 
	padding: 0;
	padding-top: 20px;
	margin: 0;
}

.customTabs > ul > .active > a > .tabName {
	color: #333333 !important;
}

.customTabs > ul > li > a > .tabName {
	color: #989595 !important;
}

/* Disables scrolling the parent page when a modal is open*/
.ReactModal__Body--open {
  overflow-y: hidden;
}

.logo-section {
	display: flex;
}

.logo-section.main {
	position: absolute; 
	/*left: 20%; */
}

.login-logout {
	color: #002345;
	font-family: Open Sans, Helvetica, Arial, Verdana, Tahoma, sans-serif  !important;
	font-size: 12px;
	font-weight: 700;
	text-transform: uppercase;
	letter-spacing: 1.64;
	cursor: pointer;
	position: absolute;
	/*right: 10%;*/
	z-index: 1000;
}

.language-link {
	/*margin-top: 20px;*/
	/*margin-left: 8px;*/
	font-family: 'Open Sans', Helvetica, Arial, Verdana, Tahoma, sans-serif; 
	font-weight: 400; 
	/*color: #0071BC;*/
	font-size: 14px;
}

.language-link.main {
	/*margin-left: 48px;*/
	/*margin-top: 25px;*/
	z-index: 1000;
}

.language-link.sticky {
	margin-top: 10px;
}

.language-link.sticky-small {
	margin-top: -5px;
	margin-left: 0px;
	font-size: 12px;
}

/*#english-link, #pt-link, #english-link:hover, #pt-link:hover {
	margin-left: 8px; 
	cursor: pointer; 
	margin-right: 8px;
	color: #002345;
	text-decoration: none;
}*/

.language-link-bold {
	font-weight: 900;
	color: #ea5228 !important;
}

@font-face {
  font-family: 'Dubai-Bold';
  src: url('./dubai-font/fonts/Dubai-Bold.eot?#iefix') format('embedded-opentype'), url('./dubai-font/fonts/Dubai-Bold.woff') format('woff'), url('./dubai-font/fonts/Dubai-Bold.ttf') format('truetype'), url('./dubai-font/fonts/Dubai-Bold.woff2#Dubai-Bold') format('woff2');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Dubai-Medium';
  src: url('./dubai-font/fonts/Dubai-Medium.eot?#iefix') format('embedded-opentype'), url('./dubai-font/fonts/Dubai-Medium.woff') format('woff'), url('./dubai-font/fonts/Dubai-Medium.ttf') format('truetype'), url('./dubai-font/fonts/Dubai-Medium.woff2#Dubai-Medium') format('woff2');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Dubai-Regular';
  src: url('./dubai-font/fonts/Dubai-Regular.eot?#iefix') format('embedded-opentype'), url('./dubai-font/fonts/Dubai-Regular.woff') format('woff'), url('./dubai-font/fonts/Dubai-Regular.ttf') format('truetype'), url('./dubai-font/fonts/Dubai-Regular.woff2#Dubai-Regular') format('woff2');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Dubai-Light';
  src: url('./dubai-font/fonts/Dubai-Light.eot?#iefix') format('embedded-opentype'), url('./dubai-font/fonts/Dubai-Light.woff') format('woff'), url('./dubai-font/fonts/Dubai-Light.ttf') format('truetype'), url('./dubai-font/fonts/Dubai-Light.woff2#Dubai-Light') format('woff2');
  font-weight: normal;
  font-style: normal;
}
.font-dubai {
  font-family: 'Dubai-Regular', Helvetica, sans-serif;
  font-weight: normal;
  font-style: normal;
}

.categories-ar h1 {
	color: #333333;
	font-family: 'Dubai-Regular', Helvetica, sans-serif;
	font-size: 32px;
	font-weight: 700;
	letter-spacing: -0.26px;
	line-height: 39px;
}

.categories-ar h2 {
	color: #333333;
	font-family: 'Dubai-Regular', Helvetica, sans-serif;
	font-size: 23px;
	font-weight: 700;
	letter-spacing: -0.19px;
	line-height: 32px;
}

.categories-ar h3 {
	color: #333333;
	font-family: 'Dubai-Regular', Helvetica, sans-serif;
	font-size: 18px;
	font-weight: 700;
	letter-spacing: -0.15px;
	line-height: 25px;
}

.categories-ar h4 {
	color: #333333;
	font-family: 'Dubai-Regular', Helvetica, sans-serif;
	font-size: 14px;
	font-weight: 700;
	letter-spacing: 1.75px;
	line-height: 19px;
	text-transform: uppercase;
}

.categories-ar h5 {
	color: #333333;
	font-family: 'Dubai-Regular', Helvetica, sans-serif;
	font-size: 12px;
	font-weight: 700;
	letter-spacing: 1.75px;
	line-height: 19px;
	text-transform: uppercase;
}

.categories-ar body {
	color: #333333;
	font-family: 'Dubai-Regular', Helvetica, sans-serif;
	font-size: 15px;
	font-weight: 400;
	letter-spacing: -0.13px;;
	margin: 0;
  	padding: 0;
}

.categories-ar .footnote {
	color: #333333;
	font-family: 'Dubai-Regular', Helvetica, sans-serif;
	font-size: 11px;
	font-weight: 400;
	line-height: 19px;
}