#insights-container {
	padding-top: 72px;
	padding-bottom: 144px;
	padding-left: 120px;
	padding-right: 120px;
}

.sentiment-page {
	width: 80% !important;
	margin-left: 10% !important;
}

.sentiment-desc {
	color: #333333;
	font-size: 18px;
	font-weight: 400;
	line-height: 30px;
	text-align: left;
	padding-bottom: 20px;
}

.sentiment-theme-icon {
	height: 55px;
}

.sentiment-theme-buttons > .sentiment-theme {
	background-color: transparent;
	border: none;
}

.sentiment-theme:hover {
	border-radius: 3px;
}

.sentiment-theme-buttons > .sentiment-theme.active {
	background-image: linear-gradient(206deg, #065662 0%, #00427c 100%);
	border-radius: 3px !important;
	color: white;
}

.sentiment-theme-name {
	width: 100px;
	display: inline-block;
	white-space: break-spaces;
	height: 80%;
	vertical-align: middle;

	color: #333333;
	font-size: 14px;
	font-weight: 600;
	line-height: 21px;
}

.sentiment-theme.active > .sentiment-theme-name {
	color: #fff;
}

.sentiment-actions {
	margin-top: 30px;
}

#sentimentToggle {
	float: right;
}

.sentiment-map {
	width: 80%;
}

.theme-wordcloud, .sentiment-chart {
	width: 100%;
}

.sentiment-graphic-map, .sentiment-graphic-chart {
	border: 1px solid #ccc;
	border-radius: 5px;
	background: #fff;
}

.sentiment-graphic-chart {
	padding: 10px;
}

.sentiment-graphic-chart iframe {
	height: 300px;
}

.sentiment-graphic-cloud {
	background-color: #002748;
	border-radius: 5px;
}

.sentiment-graphic-map, .sentiment-graphic-chart, .sentiment-graphic-cloud {
	margin-top: 30px;
}

.media-container {	
	/*background: #fff;*/
	/*border: 1px solid #ccc;*/
	/*border-radius: 5px;*/
	/*margin-top: 30px;*/
	width: 100% !important;
	margin-left: 0px !important;
}

.media-feed {
	text-align: left;
	/*padding: 80px !important;*/

}

.media-object {
	padding: 0px !important;
}

.media-object > a:hover {
	text-decoration: none !important;
}

/* .media-object > a:hover > .media-article {
	background-image: linear-gradient(206deg, #006169 0%, #006169 100%) !important;
}

.media-object > a:hover > .media-article > .media-text > .media-article-date, .media-object > a:hover > .media-article > .media-text > .media-article-title {
	color: #fff !important;
} */

#insights-container .divider {
	width: 82px;
	height: 0px;
	border-bottom: 2px solid #CDE7F9;
	margin-top: 5px;
}

#insights-container .media-title {
	font-family: 'Andes', sans-serif;
	color: #1A1A1A;
	font-size: 40px;
	line-height: 40px;
	font-weight: 400;
	text-align: left;
}

#insights-container .media-desc {
	text-align: left;
	color: #1A1A1A;
	font-size: 22px;
	line-height: 29.96px;
	font-weight: 400;
	font-family: 'Open Sans', sans-serif;
	margin-top: 40px;
	margin-bottom: 48px;
}

.media-article {
	margin-right: 30px;
	margin-bottom: 32px;
	padding: 24px;
	height: 124px;
	background: #fff;
	display: flex;
	box-shadow: 0px 2px 8px 2px rgba(123, 123, 123, 0.32);
}

.media-text {
	margin: auto 0px;
}

.media-article-divider {
	border-top: 1px solid #ccc;
	height: 30px;
}

.media-article-date {
	color: #666666;
	font-family: 'Andes', sans-serif;
	font-size: 14px;
	font-weight: 400;
	line-height: 14px;
	margin-bottom: 12px;
}

.media-article-title {
	color: #1A1A1A;
	font-size: 18px;
	font-weight: 400;
	line-height: 25px;
	font-family: 'Open Sans', sans-serif;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
   	-webkit-line-clamp: 2; /* number of lines to show */
            line-clamp: 2; 
   -webkit-box-orient: vertical;
}

.media-article-title:hover {
	text-decoration: none !important;
}

.media-article-theme {
	color: #333333;
	font-size: 14px;
	font-weight: 400;
	line-height: 28px;
}

.btn-show-more-media {
	width: 143px;
	height: 57px;
	background: #009FDA;
	display: flex;
	align-items: center;
	justify-content: center;
	align-self: flex-end;
	margin-left: auto;
	color: white;
	font-size: 18px;
	margin-bottom: 144px;
	cursor: pointer;
}

.btn-show-more-media:hover {
	text-decoration: underline;
	filter: brightness(.9);
}

.media-showmore {
	text-align: right;
	margin-right: 30px;
}

.media-show {
	width: 100px;
	background-color: #0071bc;
	color: #fff;
	border-radius: 25px !important;
	text-transform: uppercase;
	font-size: 12px !important;
	font-weight: 600 !important;
}

.media-show:hover {
	width: 100px;
	background-color: #fff;
	color: #0071bc !important;
	border: 1px solid #0071bc;
	border-radius: 25px !important;
	text-transform: uppercase;
	font-size: 12px !important;
	font-weight: 600 !important;
}

.sentiment-title {
	font-size: 16px;
	font-weight: 600;
	text-align: left;
	padding: 5px 15px 0px 15px;
	color: #333;
}

.sentiment-title.cloud {
	color: #fff;
}