.filter-projects-modal {
  position: fixed;
  top: 0px;
  left: 0px;
  background: rgba(26, 26, 26, 0.5);
  width: 100%;
  height: 100vh;
  overflow-y: hidden;
  z-index: 99999999;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 900px) {
	.filter-projects-modal-content .line {
    flex-direction: column;
  }

  .date {
    width: auto !important;
    margin-top: 1.3rem;
  }
}

@media (max-width: 400px) {
  .filter-projects-modal-content div {
    flex-direction: column;
  }
  .checkbox-container {
    margin-right: 0 !important;
  }

  .filter-projects-modal-content .button {
    margin-top: 10px !important;
    flex-direction: row !important;
  }

  .filter-projects-modal {
    max-height: 100vh !important;
    overflow-y: auto;
  }
}
.filter-projects-modal-container {
  background-color: #fff;
  padding-left: 48px;
  padding-right: 48px;
  padding-top: 52px;
  padding-bottom: 52px;
  min-width: 300px;
  width: 100%;
  max-width: 1050px;
}

.filter-projects-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 32px;
  border-bottom: 1px solid #E6E6E6;
  margin-bottom: 40px;
}

.filter-projects-modal-title {
  color: #002244;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: normal;
}

.checkbox-container {
  display: flex;
  align-items: center;
  gap: 12px;
}

.checkbox-container input[type=checkbox] {
    transform: scale(1.846);
}

.checkbox-container input[type=radio] {
    transform: scale(1.5);
}

.checkbox-container label {
  color: #666666 !important;
  font-family: 'Open Sans', sans-serif !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 22px !important;
  line-height: normal !important;
}

#force-margin-0 {
  margin: 0px !important;
}

.filter-field-label {
  color: #666666;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: normal;
  margin-right: 16px;
}

.filter-field-value {
  color: #B4B4B4 !important;
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  font-size: 1.3rem !important;
  line-height: normal;
}

.dropdown-menu {
  max-height: 250px;
  overflow-y: scroll;
}

.checkbox-container label {
  margin: auto;
}

.input-date {
  border: none;
  outline: none;
}