.row {
  display: flex;
  width: 100%;
  align-items: flex-start;
  gap: 16px;
}

.row > * {
  width: 100%;
  flex: 1;
}

.rowCenter {
  align-items: center;
}

.column {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.columnItem {
  margin-bottom: 20px;
  width: 100%;
  text-align: left;
  padding: 12px 24px;
  border: 1px solid #e6e6e6;  font-size: 16px;
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
}

.columnItemTitle {
  font-weight: bold;
  font-family: 'Andes';
  text-transform: uppercase;
  margin-bottom: 16px;
  text-align: left;
}