.finance-page {
	margin: auto;
}

.focus-desc {
	color: #333333;
	font-size: 0.9rem;
	font-weight: 400;
	line-height: normal;
	text-align: left;
	padding-bottom: 20px;
}

#focus-columns {
	/*width: 80% !important;*/
	/*margin-left: 10% !important;*/
}

.focus-column {
	/* Have same format as other sections for smaller screen sizes */
	margin-top: 30px;

	/* Styling for all sizes */
	border-radius: 5px;
	height: 300px;
	background: #fff;
	text-align: center;
	position: relative;
	z-index: 1
}

.focus-column:hover {
	cursor: pointer;
}

.focus-column.active {
	/*background-image: linear-gradient(206deg, #006169 0%, #006169 100%);*/
	background: #028294;
}

.focus-icon {
	position: relative;
	top: 0;
	left: 0;
	height: 100px;
	width: 100px;
	margin: 0 auto;
	/*border-radius: 100px;*/
	/*background-image: linear-gradient(-135deg, #065662 0%, #0071bc 100%);*/
}

.img-circle {
	position: relative;
	top: 0;
	left: 0;
	height: 100px;
}

.img-icon {
	position: relative;
	top: -100px;
	display: inline-block;
	font-size: 50px;
	line-height: 100px;
	color: #058a8f;
}

.focus-text {
	/*width: 80%;*/
	/*margin-left: 10%;*/
	margin-top: 10%;
}

.focus-label {
	padding-top: 10px;
	color: #0071bc;
	font-size: 10px;
	font-weight: 600;
}

.focus-button {
	margin: 5px;
	position: relative;
	z-index: 100;
}

.focus-caret {
	/* color: #4cbb88; */
}

.focus-column.active > .focus-text > .focus-label, .focus-column.active > .focus-text > .focus-title {
	color: #fff;
}

.focus-column.active > .focus-text > .focus-caret {
	color: transparent;
}

.active-strategy-caret {
	color: #028294 !important;
	margin-top: -11px;
    display: block;
}

.focus-title {
	font-size: 10px;
	width: 96%;
}

#focus-desc {
	text-align: left;
}

#focus-desc-title {
	font-size: 2rem;
	line-height: normal;
	font-weight: 400;
	color: #1A1A1A;
	font-family: 'Andes', sans-serif;
	margin-top: 39px;
	margin-bottom: 24px;
}

.focus-paragraph {
	font-family: 'Open Sans', sans-serif;
	line-height: normal;
	color: #4D4D4D;
	font-size: 0.9rem;
	margin-bottom: 18px;
}

#objective-columns {
	display: flex;
	flex-direction: column;
	gap: 24px;
	margin-top: 18px;
}

.objective-column {
	border-radius: 5px;
	height: 300px;
	/*background-image: linear-gradient(206deg, #006169 0%, #006169 100%);*/
	background: #028294;
	text-align: left;
}

.objective-text {
	width: 80%;
	padding-left: 15%;
	padding-top: 15%;
	height: 100%;
	margin-top: 5%;
}

.objective-title {
	color: #fff;
	font-size: 10px;
}

.objective-description {
	/* Style for "Increase e" */
	color: #fff;
	font-size: 10px;
	font-weight: 600;
	line-height: normal;
}

.objective-buttons {
	text-align: center;
	margin-top: 5%;
}

.yellow-strat-button {
    color: #333 !important;
    /* background: #4cbb88 !important; */
    font-size: 10px !important;
    border: 0px !important;
    border-radius: 0px !important;
    text-transform: uppercase !important;
    line-height: 15px !important;
    font-weight: 800 !important;
    letter-spacing: 1.2px;
    z-index: 1000;
}

/*.yellow-strat-button:hover {
	color: #4cbb88 !important;
	background: #333 !important;
}*/

.yellow-outline-strat-button {
    color: #fff !important;
    background: transparent !important;
    font-size: 10px !important;
    border: 1px solid #4cbb88 !important;
    border-radius: 0px !important;
    text-transform: uppercase !important;
    line-height: 15px !important;
    font-weight: 800 !important;
    letter-spacing: 1.2px;
}

/*@media  (min-width : 768px) {
	#focus-columns {
		margin-top: 0px;
	}

	.focus-column {
		margin-top: -50px;
	}
}*/

#strategy-container {
	padding-top: 56px;
	padding-bottom: 56px;
	padding-left: 120px;
	padding-right: 120px;
	display: flex;
	flex-direction: column;
}

@media (max-width: 850px) {
	#strategy-container {
		padding-left: 80px;
		padding-right: 80px;
	}
}

@media (max-width: 600px) {
	#strategy-container {
		padding-left: 20px !important;
		padding-right: 20px !important;
	}
}

#strategy-container h2 {
	color: #002244;
	font-family: 'Andes', sans-serif;
	font-size: 3rem;
	line-height: normal;
	font-weight: 400;
	margin: 0px;
	margin-bottom: 6px;
	text-align: left;
}

#strategy-container .divider {
	width: 82px;
	height: 0px;
	border-bottom: 2px solid #CDE7F9;
	margin-bottom: 40px;
}

.focus-areas-cards {
	display: flex;
	gap: 1.3rem;
	overflow-x: auto;
	padding-bottom: 1.3rem;
}

.objective-card {
	padding: 24px;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 2px 8px 2px rgba(123, 123, 123, 0.32);
  width: 100%;
  align-items: flex-start;
  background-color: #fff !important;
}

.objective-card h3 {
  margin: 0px;
  color: #002244;
  font-family: 'Andes', sans-serif;
  font-size: 2rem;
  line-height: normal;
  font-weight: 400;
  text-align: left;
}

.objective-card span {
  margin: 0px;
  color: #1A1A1A;
  font-family: 'Open Sans', sans-serif;
  font-size: 0.8rem;
  line-height: normal;
  font-weight: 400;
  text-align: left;
  line-height: 21.79px;
}

.objective-card-footer {
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-top: 16px;
}

#strategy-container {
	padding-bottom: 0 !important;
}

#strategy-container p {
	text-align: left;
	color: #4d4d4d!important;
    font-family: "Open Sans",sans-serif!important;
    font-size: 18px!important;
    line-height: 24.51px!important;
    font-weight: 400!important;
    padding: 0!important;
    display: block!important;
}

#strategy-container p.card-money-amount {
	margin-top: 20px;
	text-align: right;
	color: #262626;
	font-family: 'Andes', sans-serif !important;
}

#strategy-container p.card-money-amount span {
	display: inline !important;
	margin-right: 15px;
}
