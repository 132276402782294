.modalWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.ModalWrapperFull {
  height: 100%;
}

.UserReviewRow {
  display: flex;
  gap: 32px;
}
/* 
.UserReviewRow:not(:last-child){
  margin-bottom: 16px;
} */

.Form {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.QuestionWrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: fit-content;
  gap: 8px;
  margin-bottom: 12px;
}

.InputWrapper {
  display: block;
  max-width: 240px;
}

.InputWrapper:not(:last-child) {
  margin-right: 24px;
}

.InputWrapperSmall {
  max-width: 120px;
}

.InputWrapperLarge {
  max-width: 600px;
  min-width: 500px;
  width: 100%;
}

.InputWrapperFull {
  width: 100%;
  min-width: 720px;
  max-width: unset;
}

.UserReviewLabel {
  text-align: left;
  font-size: 12px;
  font-weight: 700;
  margin-bottom: 4px;
  color: #002244;
  max-width: 400px;
  width: 100%;
}

.TextInput {
  margin-right: 24px;
  flex: 1;
}

.TextInput:not(:last-child) {
  padding-right: 24px;
}

.ButtonsWrapper {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.ButtonsWrapper:not(:last-child) {
  margin-bottom: 32px;
}

.ActionButton:not(:last-child) {
  margin-right: 32px;
}

.CancelButton {
  margin-right: 8px;
}

.CancelButton:hover {
  color: #002244;
}

.UserReviewSelectError {
  color: #e74c3c;
  font-size: 12px;
  margin-top: 4px;
}

.CheckboxWrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}

.CheckboxItem {
  display: flex;
  flex-direction: column-reverse;
}

.CheckboxItem span {
  font-size: 24px;
}
