div.menu-buttons:hover {
	cursor: pointer;
}
.menu {
	margin: 0 auto;
	left: 0;
	right: 0;
	height: 100%;
	width: 100%;
}
#loginError {
	color: red;
	font-family: 'Open Sans', sans-serif;
	font-size: 9px;
	font-weight: 700;
	line-height: 30px;
	letter-spacing: 1.64px;
}
#menu-bg {
	z-index: 10;
	position: absolute;
}

#menu-overlay {
	z-index: 20;
}


#login-button:hover {
	background-color: #0071bc;
	color: #fff;
}

#main-login-line { 
	stroke: #0071bc;
	background-color: #0071bc;
}

#menu-logo {
	position: absolute;
	left: -80%;
}

#main-menu-container {
	margin: 0 auto;
	transform: translate(0%, 3%);
}

#home-container {
	margin: auto;
}

.welcome-divider {
	width: 80%;
	border-bottom: 1px solid #ccc;
	height: 27px;
	margin-left: 10%;
}

#about-container {
	margin: 0 auto;
	transform: translate(0%, 3%);
	/*height: 300px;*/
	/*width: 95%;*/
	text-align: left;
	/*margin-left: 10%;*/
}

#about-title {
	font-size: 23px;
	font-weight: 900;
	line-height: 14px;
	text-align: left;
	font-family: 'Andes',Helvetica, Arial, Verdana, Tahoma, sans-serif;
}

#about-line {
	stroke: #4cbb88;
	background-color: #4cbb88;
	border: 2px solid #4cbb88;
}

.about-text {
	/*width: 50%;*/
	font-size: 16px;
	line-height: 26px;
	text-align: left;
	color: #333;
	margin: 10px 0px !important;
}

.about-text.page-button {
	color: #333 !important;
	background-color: #4cbb88 !important;
	/*border-radius: 25px !important;*/
}

.about-text > a {
	color: #cd2c0f;
}

.about-text > a:hover {
	color: #cd2c0f;
}

.about-link {
	font-weight: 600;
}

.about-link:hover {
	cursor: pointer;
}

#main-menu-text {
	position: absolute;
	bottom: 10%;
}

#title-2 {
	text-align: right;
	color: #0071BC;
}

#readmore-containers {
	padding-top: 20px;
	width: 80% !important;
	margin-left: 10% !important;
}

.readmore-container {
	height: 400px;
	border: 1px solid lightgray;
	border-radius: 5px;
	background: #fff;
	text-align: left;
}

#finance-container, #risk-container {
	margin-top: 10px;
	text-align: left;
}

/* #strategy-container {
	margin-top: 40px;
	background-color: #4cbb88;
	background-image: linear-gradient(180deg, rgba(0, 184, 117, 0) 0%, #005d3b 100%);
	width: 100% !important;
	margin-left: 0px !important;
	height: 300px;
	border-radius: 5px;
	color: #333;
	margin-bottom: -40px;
} */

#carousel-container {
	/*width: 95% !important;*/
	margin: 0 auto;
	/*margin-top: 20px;*/
	height: 400px;
	/*display: flex;
	justify-content: center;
	align-items: center;
	overflow: hidden;*/
}

#carousel-title {
	text-align: left;
	padding-bottom: 10px;
	/*width: 80% !important;*/
	/*margin-left: 10% !important;*/
	margin-top: 20px;
}

#welcome-carousel {
	border: 10px solid #002345;
}

.carousel-img {
	object-fit: cover;
	width: 100%;
	height: 500px !important;
	overflow: hidden;
}

.carousel-caption {
	color: #fff !important;
	text-align: center;
	text-shadow: none !important;
	background-color: #002345;
	/*border-radius: 5px;*/
	padding-top: 0px !important;
	width: 100%;
	left: 0% !important;
	bottom: -1px !important;
	height: 130px;
}

#right-column {
	/* Have same format as other sections for smaller screen sizes */
	margin-top: 140px;
	/*width: 90% !important;*/
	/*margin-left: 5% !important;*/

	/* Styling for all sizes */
	border-radius: 5px;
	/*height: 915px;*/
	background: #fff;
	/*background-image: linear-gradient(#fff 0%, #f6f6f6 100%);*/
	z-index: 100;
	position: relative;
}

.readmore-image {
	text-align: center;
}

.readmore-image > img {
	/*height: 200px;*/
	/*width: 100%;*/
	background: gray;
	margin-top: 5%;
	max-width: 100%;
	max-height: 226px;
}

.readmore-title {
	padding-top: 10px;
	font-size: 20px;
	font-weight: 600;
}

.readmore-desc {
	padding-top: 10px;
	font-size: 14px;
	color: #222;
}

.readmore-link {
	display: flex;
	padding-top: 10px;
	color: #0071BC;
	font-size: 12px;
}

.readmore-link:hover {
	cursor: pointer;
	text-decoration: none;
}

.readmore-icon {
	padding-left: 5px;
	margin-top: -4px;
}

.strategy-image > img {
	height: 300px;
	max-width:100%;
}

.strategy-text {
	text-align: center;
	margin-top: 50px;
	height: 120px;
	top: 70px;
	align-items: center;
	display: grid;
	position: absolute;
    width: 100%;
    left: 50%;
  	-ms-transform: translate(-50%, -50%);
  	transform: translate(-50%, -50%);
}

.strategy-btn {
	margin: 0;
    position: absolute;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    top: 150px;
}

.landing-large {
	margin-left: 0 !important;
}

.page-links {
	color: white;
}

.page-intro {
	font-size: 18px;
	font-weight: bold;
}

.page-large {
	font-size: 50px;
	font-weight: bold;
	font-family: 'Kaushan Script', Helvetica, Arial, Verdana, Tahoma, sans-serif;
	margin-left: -20px;
}

.page-desc {
	font-size: 16px;
	width: 90%;
	margin-left: 5%;
}

.page-button {
	margin-top: 10px;
    background: #333 !important;
    font-size: 10px !important;
    border: 0px !important;
    border-radius: 0px !important;
    text-transform: uppercase !important;
    line-height: 16px !important;
    font-weight: 800 !important;
    letter-spacing: 1.2px;
    color: #fff !important;
}

.strategy-text > .page-button {
	width: 100px;
}

#video {
	text-align: left;
	width: 100%;
	padding-left: 10%;
}

#video-title {
	padding-top: 10px;
	font-size: 14px;
	text-transform: uppercase;
}

#video-imbed {
	text-align: center;
}

#video-imbed > img {
	/*height: 200px;*/
	/*width: 100%;*/
	background: gray;
	margin-top: 5%;
	max-width: 100%;
	max-height: 226px;
}

#video-sub {
	padding-top: 10px;
	font-size: 10px;
	text-transform: uppercase;
}

#video-desc {
	font-size: 18px;
}

#landing-tutorial-title {
	color: #333;
	font-family: 'Andes' , Helvetica, Arial, Verdana, Tahoma, sans-serif  !important;
}

#landing-tutorial-title:hover {
	cursor: pointer;
}

#landing-benchmarking {
	height: 300px;
	width: 90%;
	margin-left: 5%;
	background-color: #cd2c0f;
	border-radius: 5px;
	display: flex;
}

.landing-benchmarking-text {
	margin: auto;
}

.landing-benchmarking-text > .page-button {
	background: #fff !important;
	color: #cd2c0f !important;
}

#landing-story {
	height: 300px;
	width: 90%;
	margin-left: 5%;
	background-color: #cd2c0f;
	border-radius: 5px;
	display: flex;
}

.landing-story-text {
	margin: auto;
}

.landing-story-text > .page-button {
	background: #fff !important;
	color: #cd2c0f !important;
}

#landing-covid {
	height: 300px;
	width: 90%;
	margin-left: 5%;
	background-color: var(--color-primary);
	border-radius: 5px;
	display: flex;
}

.landing-covid-text {
	margin: auto;
}

.landing-covid-text > .page-button {
	background: #fff !important;
	color: #002245 !important;
}

#downloads {
	text-align: left;
	width: 100%;
	padding-left: 10%;
	padding-bottom: 15px;
}

#downloads-title {
	font-size: 14px;
	text-transform: uppercase;
}

.downloads-file {
	display: flex;
	padding-top: 10px;
}

.downloads-icon {
	color: red;
	padding: 10px;
}

.downloads-text {
	padding-top: 10px;
	font-size: 14px;
	font-weight: bold;
}

.downloads-filename {
	color: #0071BC;
}

.downloads-filename:hover {
	cursor: pointer;
	text-decoration: underline;
}

.downloads-size {
	color: grey;
	padding-left: 5px;
}

.login-form {
	margin-top: 40px;
}

.login-form > #form-title {
	color: #0071bc;
	font-family: 'Open Sans', sans-serif;
	font-weight: 900;
	text-transform: uppercase;
}

.login-form > div.form-group > input {
    color: #333;
    font-family: Open Sans,sans-serif;
    font-size: 13px;
    font-weight: 700;
    line-height: 17px;
    text-align: center;
    border-bottom: 2px solid #333;
    border-radius: 0;
    border-top: none;
    border-left: none;
    border-right: none;
}

#login-button {
	border: 2px solid #0071bc;
	background-color: #ffffff;
	color: #333;
	font-family: 'Open Sans', sans-serif;
	font-weight: 900;
	text-transform: uppercase;
}

/* Medium Devices, Desktops */
@media (min-width: 992px) { 
	/* #strategy-container {
		margin-top: 60px;
	} */

	#finance-container {
		padding-left: 0px;
	}

	#risk-container {
		padding-right: 0px;
	}

	#right-column {
		/*margin-right: 20%;*/
		margin-top: 0px;
	}
}

/* Custom, iPhone Retina */ 
@media only screen and (min-width : 300px) { /* *.39 */
	#menu-logo {
		width: 25px;
		height: 25px;
		/*left: -215px;*/
		top: 5px;
	}
	#main-menu-container {
		height: 200px; 
		width: 95%;
		position: relative;
		/*left: 14px;*/
		right: 0;
	}

	#menu-bg {
		left: 0px;
		top: 20px;
	}

	#menu-bg-logged-in {
		width: 268px;
		height: 285px;
		position: absolute;
		top: 78px;
		left: -19px;
	}

	#menu-overlay {
		width: 273px;
		height: 442px;
		left: -37px;
		top: -29px;
	}

}

/* Extra Small Devices, Phones */ 
@media only screen and (min-width : 480px) { /* *.625 */
	#menu-logo {
		width: 41px;
		height: 41px;
		/*left: -350px;*/
		top: 3px;
	}
	#main-menu-container {
		height: 400px; 
		width: 95%;
		position: relative;
		/*left: 22px;*/
		right: 0;
	}

	#menu-bg {
		left: 0px;
		top: 31px;
	}

	#menu-bg-logged-in {
		width: 430px;
		height: 456px;
		position: absolute;
		top: 125px;
		left: -31px;
	}

	#menu-overlay {
		width: 437px;
		height: 709px;
		left: -59px;
		top: -47px;
	}
	
	#title-1 {
		/*width: 217px;*/
		/*height: 23px;*/
		color: #1e4c61;
		font-family: 'Open Sans', sans-serif;
		font-size: 10px;
		font-weight: 700;
		/*letter-spacing: -0.0875px;*/
		/*line-height: 23px;*/
/*		text-transform: uppercase;
		position: absolute;
		top: 38px;
		left: 126px;*/
		text-align: left;
	}

	#title-2 {
		/*width: 217px;*/
		/*height: 23px;*/
		/*color: #0c7677;*/
		font-family: 'Open Sans', sans-serif;
		font-size: 54px;
		font-weight: 900;
		/*letter-spacing: -0.156px;*/
		/*line-height: 38px;*/
/*		text-transform: uppercase;
		position: absolute;
		top: 68px;
		left: 126px;*/
		/*text-align: left;*/
	}

	#desc {
		/*width: 217px;*/
		/*height: 45px;*/
		color: white;
		font-family: 'Open Sans', sans-serif;
		font-size: 9px;
		font-weight: 700;
		/*letter-spacing: -0.08px;*/
/*		line-height: 15px;
		position: absolute;
		top: 144px;
		left: 222px;*/
		text-align: left;
	}

}

/* Small Devices, Tablets */
@media only screen and (min-width : 768px) {
	/* #strategy-container {
		margin-top: 120px;
	} */

	#menu-logo {
		width: 65px;
		height: 65px;
		/*left: -580px;*/
		top: 5px;
	}
	#main-menu-container {
		height: 600px; 
		width: 95%;
		position: relative;
		/*left: 35px;*/
		right: 0;
	}

	#menu-bg {
		left: 0px;
		top: 50px;
	}

	#menu-bg-logged-in {
		width: 688px;
		height: 730px;
		position: absolute;
		top: 200px;
		left: -50px;
	}

	#menu-overlay {
		width: 700px;
		height: 1134px;
		left: -95px;
		top: -75px;
	}

	#title-1 {
		/*width: 347px;*/
		/*height: 36px;*/
		color: #1e4c61;
		font-family: 'Open Sans', sans-serif;
		font-size: 16px;
		font-weight: 700;
		/*letter-spacing: -0.14px;*/
		/*line-height: 36px;*/
/*		text-transform: uppercase;
		position: absolute;
		top: 60px;
		left: 202px;*/
		text-align: left;
	}

	#title-2 {
		/*width: 347px;*/
		/*height: 36px;*/
		/*color: #0c7677;*/
		font-family: 'Open Sans', sans-serif;
		font-size: 88px;
		font-weight: 900;
		/*letter-spacing: -0.25px;*/
		/*line-height: 60px;*/
/*		text-transform: uppercase;
		position: absolute;
		top: 108px;
		left: 202px;*/
		/*text-align: left;*/
	}

	#desc {
		/*width: 347px;*/
		/*height: 72px;*/
		color: white;
		font-family: 'Open Sans', sans-serif;
		font-size: 14px;
		font-weight: 700;
		/*letter-spacing: -0.13px;*/
		/*line-height: 24px;*/
		/*position: absolute;
		top: 170px;
		left: 355px;*/
		text-align: left;
	}

}

/* Medium Devices, Desktops */
@media only screen and (min-width : 992px) {

}

/* Large Devices, Wide Screens */
@media only screen and (min-width : 1200px) {

}

.welcome-projects-container {
	width: 100%;
	padding: 56px;
	padding-left: 120px;
	padding-right: 120px;
	background-color: rgb(250, 250, 250);
}

.welcome-projects-content {
	padding-left: 80px;
	padding-right: 80px;
	background-color: rgb(11, 148, 162);
	width: 100%;
	height: 100%;
	color: white;
	display: flex;
	align-items: center;
	justify-content: space-between;
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
}

.welcome-projects-title {
	font-family: 'Andes', sans-serif;
	font-size: 40px;
	line-height: 40px;
	margin-bottom: 34px;
}

.welcome-projects-desc {
	text-align: left;
	font-size: 18px;
	font-family: 'Open Sans', sans-serif;
	line-height: 24.51px;
	font-weight: lighter;
	margin-bottom: 46px;
}

.welcome-projects-content a {
	margin-bottom: 20px !important;
}
.welcome-projects-info-title {
	font-family: 'Open Sans', sans-serif;
	font-weight: 400;
	font-size: 22px;
	line-height: 29.96px;
	font-weight: 100;
}

.welcome-projects-info-quantity {
	font-family: 'Andes', sans-serif;
	font-weight: 400;
	font-size: 40px;
	line-height: 40px;
}

.welcome-cards-container {
	background: rgb(242, 242, 242);
	height: 402px;
	padding: 54px;
	padding-left: 120px;
	padding-right: 120px;
	display: flex;
	gap: 30px;
}

.welcome-last-projects-container {
	background: rgb(250, 250, 250);
	padding: 68px;
	padding-left: 120px;
	padding-right: 120px;
	padding-bottom: 144px;
	display: flex;
	flex-direction: column;
	gap: 32px;
}

.welcome-last-projects-container .header {
	display: flex;
	justify-content: space-between;
	width: 100%;
}

.welcome-last-projects-container .header span {
	font-family: 'Andes', sans-serif;
	color: black;
	font-size: 40px;
	line-height: 40px;
}

@media (max-width: 850px) {
	.welcome-last-projects-container, .welcome-cards-container, .welcome-projects-container	 {
		padding-left: 40px;
		padding-right: 40px;
	}

	.welcome-projects-content {
		display: flex;
		flex-direction: column;
	}
}

@media (max-width: 600px) {
	.welcome-last-projects-container, .welcome-cards-container, .welcome-projects-container	 {
		padding-left: 20px !important;
		padding-right: 20px !important;
	}

	.welcome-projects-content {
		padding-left: 20px !important;
		padding-right: 20px !important;
		padding-bottom: 40px;
	}

	.welcome-projects-content div {
		width: 100% !important;
	}

	.welcome-projects-content a {
		margin-bottom: 20px !important;
	}

}

.welcome-areas {
	padding-left: 120px;
	padding-right: 120px;
}
@media (max-width: 850px) {
	.welcome-areas {
    padding-left: 40px !important;
    padding-right: 40px !important;
  }
}

@media (max-width: 600px) {
	.welcome-areas {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
}