/* Copy Text */
.reportsTextContent {
  display: flex;
  flex-direction: column;
  /* gap: 24px; */
  justify-content: flex-start;
  padding-top: 56px;
  padding-left: 120px;
  padding-right: 120px;
}

.reportsTextContent h2 {
  font-family: 'Andes';
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  line-height: 40px;
  color: #1A1A1A;
  text-align: left;
  margin: 0px;
}

.reportsTextContentDivider {
	width: 82px !important;
	height: 0px !important;
	border-bottom: 2px solid #CDE7F9 !important;
  margin-top: 8px !important;
  margin-bottom: 40px !important;
}

.reportsTextContent p {
  text-align: left;
  color: #4D4D4D;
  font-family: 'Open Sans', sans-serif;
  font-size: 18px;
  line-height: 24.51px;
  margin: 0px;
}

.reportCardsFiltersLabel {
  color: var(--color-primary);
  font-size: 14px;
  text-transform: uppercase;
  font-weight: bold;
}

.reportCardsFiltersWrapper {
  max-width: fit-content;
  display: inline-block;
  text-align: left;
  margin-right: auto;
}


/* Cards */
.reportCardsSection {
  padding-top: 72px;
  padding-bottom: 144px;
  padding-left: 120px;
  padding-right: 120px;
}

.reportCardWholeWrapper {
  display: flex;
  flex-direction: column;
  background: rgb(250, 250, 250);
  padding: 24px;
  width: 100%;
}

.reportCardsWrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 50px;
  width: 100% !important;
  padding: 0px !important;
}

.reportCardsWrapper::before {
  display: none !important;
}

.reportCardWrapper {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 2fr;
  align-items: flex-start;
  gap: 32px;
  flex-grow: 1;
}

.reportCardImageWrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-end;
}

.reportCardImage {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: left;
  object-fit: contain;
}

.reportCardContentWrapper {
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: start;
  height: 100%;
}

.reportCardTitle {
  font-family: 'Open Sans', sans-serif !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 19px !important;
  color: #4d4d4d !important;
}

.reportCardCategory {
  font-family: 'Andes', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 24px !important;
  line-height: 24px;
  color: #1A1A1A;
  border-bottom: 1px solid #ececec;
  padding-bottom: 16px;
  text-transform: uppercase !important;
  text-align: left;
  margin-bottom: 16px;
}

.reportCardLink {
  align-self: end;
  margin-top: auto;
  border-radius: 4px;
  color: white;
  background: #cd2c0f;
  border-radius: 24px;
  border: none;
  outline: none;
  font-weight: bold;
  cursor: pointer;
  padding: 8px 16px;
  transition: all 300ms ease-in-out;
}

.reportCardLink:hover {
  background: #cd2b0fe1;
  color: white;
  text-decoration: none;
}

@media screen and (max-width: 768px) {
  .reportCardsWrapper {
    grid-template-columns: 1fr;
    gap: 8px;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .reportCardWrapper {
    display: flex;
    flex-direction: column;
  }

  .reportCardContentWrapper {
    justify-content: flex-start;
    flex-direction: column;
    display: flex;
  }
}