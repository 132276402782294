#finance-container {
	padding-top: 56px;
	padding-bottom: 56px;
	padding-left: 120px;
	padding-right: 120px;
	display: flex;
	flex-direction: column;
}

#finance-container h2 {
	color: #002244;
	font-family: 'Andes', sans-serif;
	font-size: 40px !important;
	line-height: 40px;
	font-weight: 400;
	margin: 0px;
	text-align: left;
}

#finance-container .divider {
	width: 82px;
	height: 0px;
	border-bottom: 2px solid #CDE7F9;
}

.finance-title {
	font-family: 'Andes', sans-serif;
	font-size: 32px !important;
	line-height: 32px;
	font-weight: 400 !important;
	color: #1A1A1A !important;
	margin: 0px;
	margin-bottom: 24px;
	margin-top: 40px;
}

.finance-desc {
	text-align: left;
	color: #4D4D4D;
	font-size: 18px;
	font-family: 'Open Sans', sans-serif;
	line-height: 24.51px;
}

.finance-desc a {
	color: #4D4D4D;
	text-decoration: underline;
}

.finance-title {
	color: #333333;
	font-size: 24px;
	font-weight: 600;
	text-align: left;
	font-family: 'Andes',Helvetica, Arial, Verdana, Tahoma, sans-serif;
}

.finance-title-line {
	width: 60px;
	border: 2px solid #ec553a;
	margin-top: 20px;
}

.finance-small-title {
	font-weight: 600;
	text-align: left;
	font-size: 18px;
	font-family: 'Andes',Helvetica, Arial, Verdana, Tahoma, sans-serif;
}

.finance-desc.bold {
	/*color: #002248;*/
	font-weight: 600;
}

.finance-data {
	background:#fff;
	border-radius: 5px;
}

.finance-data-point {
	padding: 20px 0px;
	border-bottom: 1px solid #ccc;
	width: 98% !important;
	margin-left: 1% !important;
	font-size: 16px;
	font-weight: 600;
}

.finance-data-point.final {
	border-bottom: none;
}

.finance-data-name {
	text-align: left !important;
}

.finance-data-amount {
	text-align: right !important;
}

.finance-table {
	font-size: 16px;
	color: #fff;
}

.finance-modal-title-section {
	padding: 10px;
}

.finance-modal-small-title {
	font-size: 14px;
	font-weight: 600;
}

.finance-modal-title {
	font-size: 24px;
	font-weight: 600;
}

.finance-table {
	border-collapse: separate;
}

.finance-table > thead {
	background-color: var(--color-primary);
}

.finance-table > thead > tr > th {
	border-bottom: 2px solid #fff !important;
}

.finance-table > thead > .finance-table-totals {
	background-color: #fff !important;
	color: #333;
}

.finance-table > thead > .finance-table-header-labels {
	font-size: 14px;
}

.finance-table-totals.header, .finance-table-totals.header > .finance-header-cell {
	position: sticky;
	position: -webkit-sticky;
	top: 0;
	background: #fff;
	height: 40px;
}

.finance-table-totals-mobile.header, .finance-table-totals-mobile.header > .finance-header-cell {
	background: #fff;
	height: 40px;
}

.finance-table-header-labels.header, .finance-table-header-labels.header > .finance-header-cell {
	position: sticky;
	position: -webkit-sticky;
	top: 40px;
	background-color: var(--color-primary);
	height: 60px;
}

.finance-table-header-labels-mobile.header, .finance-table-header-labels-mobile.header > .finance-header-cell {
	background-color: var(--color-primary);
	height: 60px;
}

.finance-table-header.header, .finance-table-header.header > .finance-header-cell {
	position: sticky;
	position: -webkit-sticky;
	top: 100px;
	background-color: var(--color-primary);
}

.finance-table-header-mobile.header, .finance-table-header-mobile.header > .finance-header-cell {
	background-color: var(--color-primary);
}

.finance-table > tbody > tr > td:nth-child(1) {
	background-color: var(--color-primary);
}

.finance-table > tbody > tr > td:nth-child(2), .finance-table > tbody > tr > td:nth-child(3), .finance-table > tbody > tr > td:nth-child(6), .finance-table > tbody > tr > td:nth-child(7) {
	background-color: #0071bc;
}

.finance-table > tbody > tr > td:nth-child(4), .finance-table > tbody > tr > td:nth-child(5) {
	background-color: rgba(0, 34, 69, .9); /* #002245 but at lower opacity */
}

.finance-table > tbody > tr > td {
	border-left: 1px solid #e5e5e5;
	border-top: 1px solid #e5e5e5 !important;
}

.finance-table-button {
	text-align: center;
}

.finance-button {
	padding: 15px 15px !important;
	border-radius: 30px !important;
	background-color: #4cbb88;
	color: #333 !important;
	font-weight: 600 !important;
	margin-top: -70px;
}

#finance-modal > .modal-dialog {
	width: 100%;
}

#finance-modal-close {
    float: right;
		position: absolute;
		z-index: 15;
		right: 35px;
}

#finance-modal-close:hover {
    cursor: pointer;
}

@media (min-width: 992px) { 

	.finance-page {
		/*margin-right: 20%;*/
		/*margin-top: 0px;*/
	}
}