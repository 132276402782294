#benchmark-container-content {
  padding-left: 120px;
  padding-right: 120px;
  padding-top: 56px;
}

#benchmark-container-content .benchmark-page-title {
	color: #002244;
	font-family: 'Andes', sans-serif;
	font-size: 40px;
	line-height: 40px;
	font-weight: 400;
	margin: 0px;
	text-align: left;
}

#benchmark-container-content .divider {
	width: 82px;
	height: 0px;
	border-bottom: 2px solid #CDE7F9;
  margin-top: 8px;
  margin-bottom: 40px;
}

#benchmark-container-content .benchmark-desc {
  color: #1A1A1A !important;
  font-family: 'Open Sans', sans-serif !important;
  font-size: 18px !important;
  line-height: 24.51px !important;
  font-weight: 400 !important;
  text-align: left !important;
  padding: 0px !important;
  display: block !important;
  margin-bottom: 56px;
}

@media (max-width: 850px) {
	#strategy-container {
		padding-left: 80px !important;
		padding-right: 80px !important;
	}
}

@media (max-width: 600px) {
	#strategy-container {
		padding-left: 20px !important;
		padding-right: 20px !important;
	}
}