.about-content-container {
	padding-left: 120px;
	padding-right: 120px;
	background: #fff;
	padding-top: 55px;
	padding-bottom: 120px;
}

.about-header {
	width: 100%;
	height: 317px;
	background: rgb(83, 52, 59);
	padding-left: 120px;
	padding-right: 120px;
	padding-bottom: 80px;
	display: flex;
	align-items: flex-end;
}

.about-header span {
	color: #fff;
	font-family: 'Andes', sans-serif;
	font-size: 44px;
	line-height: 44px;
}

.about-column {
	padding: 20px 40px;
	/*margin-top: 30px;*/
	/*width: 80% !important;*/
	/*margin-left: 10% !important;*/

	/* Styling for all sizes */
	border-radius: 5px;
	background: #fff;
	/*background-image: linear-gradient(#fff 0%, #f6f6f6 100%);*/
	z-index: 100;
	position: relative;
}

.about-text-sections {
	font-size: 18px;
	line-height: 24.51px;
	text-align: left;
	color: #4D4D4D;
	padding: 30px 0px;
}

.about-text-subsections {
	font-size: 16px;
	line-height: 28px;
	text-align: left;
	color: #4D4D4D;
	padding: 10px 0px;
	padding-left: 22px;
	padding-top: 20px;
}

.about-how-to {
	color: #1A1A1A;
	font-size: 32px;
	font-family: 'Andes', sans-serif;
	line-height: 32px;
	text-align: left;
	font-weight: 400;
	margin-top: 26px;
}

.about-text-body {
	font-family: 'Open Sans', sans-serif;
	font-weight: 400;
	color: #4D4D4D;
	line-height: 24.51px;
	font-size: 18px !important;
}

.about-text-body > a {
	color: #4D4D4D;
	text-decoration: underline;
}

.about-text-body > a:hover {
	color: #cd2c0f;
}

.about-text-icon {
	color: #ec553a;
}

.about-text-title {
	font-weight: 600;
	font-size: 18px;
	text-align: left;
	font-family: 'Andes', Helvetica, Arial, Verdana, Tahoma, sans-serif;
}

.about-text-overview {
	font-family: 'Open Sans', sans-serif;
	font-size: 22px;
	line-height: 29.96px;
	color: #1A1A1A;
	margin-bottom: 16px;
}

.about-title {
	font-size: 40px;
	text-align: left;
	font-family: 'Andes', sans-serif;
	color: #1A1A1A;
	font-weight: 400;
}

.about-divider {
	width: 82px;
	border: 1px solid #CDE7F9;
}

.about-accordion-section:nth-child(1) {
	margin-top: 100px;
}

.about-accordion-section {
	text-align: left;
	padding: 10px 0px;
	margin-bottom: 30px;
}

.about-accordion-section > .about-text-title {
	display: flex;
	padding-bottom: 16px;
	border-bottom: 1px solid #ccc;
}

.about-accordion-section > .about-text-title:hover {
	cursor: pointer;
}

.about-accordion-title {
	font-family: 'Andes', sans-serif;
	font-size: 24px;
	line-height: 24px;
	color: #1A1A1A;
	font-weight: 400;
	margin-left: 6px;
}

.about-accordion-icon {
	font-size: 1.25em !important;
	color: #ec553a;
}

.about-accordion-icon.open {
	padding: 4px 8px;
	height: 30px;
	width: 30px;
	border: 1px solid #ccc;
	border-radius: 25px;
	position: relative;
	top: 6px;
	left: 3px;
	margin-right: 10px;
}

.about-accordion-icon.closed {
	width: 30px;
	height: 30px;
	position: relative;
	margin-right: 10px;
	top: 11px;
	left: 16px;
}

@media (min-width: 992px) { 
	.about-column {
		/*margin-right: 20%;*/
		/*margin-top: 0px;*/
	}
}