.radio-wrapper {
    border: 1px solid #009FDA;
    border-radius: 50%;
    height: 20px;
    width: 20px;
    padding: 2px;
    cursor: pointer;
}

.radio-circle {
    width: 100%;
    height: 100%;
    background: #009FDA;
    display: none;
}

.radio-circle.selected {
    display: block;
    border-radius: 50%;
    width: 100%;
    height: 100%;
    background: #009FDA;
}
