.benchmark-container {
/*	width: 80% !important;
	margin-left: 10% !important;*/
	/*margin-top: 30px;*/
	/* padding-bottom: 50px;
	padding-left: 120px;
	padding-right: 120px; */
}

.benchmark-container .benchmark-tabs {
	display: flex;
	justify-content: flex-start;
	width: 100%;
	border-bottom: 1px solid #E5E5E5;
	gap: 50px;
	margin-top: 65px;
	margin-bottom: 30px;
}

.benchmark-container .benchmark-tabs span {
	color: #B4B4B4;
	font-family: 'Andes', sans-serif;
	font-size: 20px;
	line-height: 20px;
	font-weight: 400;
	padding-bottom: 2px;
	cursor: pointer;
}

.benchmark-container .benchmark-tabs span.active {
	border-bottom: 1px solid #0071BC !important;
	color: #0071BC !important;
}

.display {
	color: #393d41;
	font-family: 'Open Sans', sans-serif;
	font-size: 11px;
	font-weight: 700;
	letter-spacing: 2px;
	text-transform: uppercase;
}

.title {
	text-align: left;
	padding-left: 50px;
}

.benchmark-desc {
	text-align: left;
	font-size: 18px;
	line-height: 28px;
	padding: 20px;
	color: #1A1A1A
}

.expansion {
	float: left;
	width: 30px;
	height: 30px;
	border-radius: 15px;
	border: none;
	background-color: rgba(0,0,0,0.0);
}

.plus-icon {
	font-size: 1.25em !important;
	color: #ec553a;
	position: absolute;
	left: 25px;
	top: 25px;
	height: 30px;
    width: 30px;
	border: 1px solid #ccc;
	border-radius: 25px;
	padding-top: 6px;
}

.minus-icon {
	font-size: 1.25em !important;
	color: #ec553a;
	position: absolute;
	left: 38px;
	top: 32px;
}

.expansion:active, .expansion:focus {
	outline: none !important;
}

#themeSelect {
	margin-left: 10px;
	color: #393d41;
	font-family: 'Open Sans', sans-serif;
	font-size: 13px;
	font-weight: 700;
	background-color: transparent;
	border: none;
}

#themeSelect:hover {
	background-color: transparent;
	border: none;
}

#themeSelect:active {
	background-color: transparent;
/*	border: 1px solid #26bcbe !important;*/
	box-shadow: none;
}

.dropdown-icon {
	margin-left: 10px;
	-webkit-transform: rotate(180deg);     /* Chrome and other webkit browsers */
	-moz-transform: rotate(180deg);        /* FF */
	-o-transform: rotate(180deg);          /* Opera */
	-ms-transform: rotate(180deg);         /* IE9 */
	transform: rotate(180deg);             /* W3C compliant browsers */

	/* IE8 and below */
	filter: progid:DXImageTransform.Microsoft.Matrix(M11=-1, M12=0, M21=0, M22=-1, DX=0, DY=0, SizingMethod='auto expand');
}

.categoryContent {
	text-align: left;
	color: #393d41;
	font-family: 'Open Sans', sans-serif;
	font-size: 16px;
	font-weight: 400;
	line-height: 28px;
	margin: 0;
  	padding: 0;
}

.categoryContent hr {
	height: 2px;
	border-top: 1px dashed #3f4346;
	opacity: 0.5;
	margin-top: 30px;
	margin-bottom: 40px;
}

.category-title {
	color: #333333;
	font-family: 'Andes',Helvetica, Arial, Verdana, Tahoma, sans-serif;
	font-size: 14px;
	font-weight: 700;
	/*letter-spacing: 1.75px;*/
	line-height: 19px;
	text-transform: uppercase;
}

.pad-top {
	margin-top:55px;
}

.categoryHeader:hover {
	cursor: pointer;
}

.categories {
	border-radius: 5px;
}

.categories h2 {
	margin-bottom: -25px !important;
}

.benchmarking-text {
	margin-top: 25px;
	margin-bottom: 40px;
}

#strategy-container p.footnote {
	margin-top: 20px;
	font-size: 14px !important;
}
.rank-footnote {
	margin-top: 0px !important;
}
