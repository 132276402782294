.navbar-plr {
	background: #FFF !important;
	border: none !important;
	position: absolute !important;
	width: 100% !important;
	border-radius: 0 !important;
	height: 90px !important;
	z-index: 999;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding-left: 120px;
	padding-right: 120px;
}

.menu-button {
	display: none;
}
@media (max-width: 1000px) {
	.navbar-plr, .subbar {
		padding-left: 80px;
		padding-right: 80px;
	}

	.navlist {
		display: none !important;
	}
	.menu-button {
		display: block !important;
	}
}

@media (max-width: 600px) {
	.navbar-plr, .subbar {
		padding-left: 20px !important;
		padding-right: 20px !important;
	}
}

@media (max-width: 450px) {
	#wbg-logo {
		width: 80% !important;
		height: auto;
	}
}
.obr-title {
	font-family: Andes, sans-serif;
	font-size: 24px;
	color: #1A1A1A;
	/* font-weight: bold; */
	margin: 0px;
	cursor: pointer;
}

.navlist {
	display: flex;
	align-items: center;
	list-style-type: none;
	gap: 32px;
	margin: 0px;
	justify-content: flex-start;
}

.navlist li {
	font-family: 'Open Sans', sans-serif;
	font-size: 18px;
	font-weight: 400;
	color: #1A1A1A;
	position: relative;
}

.navlist > li.pointer {
	cursor: pointer;
}

.navlist > li.selected {
	border-bottom: 2px solid #002244;
}

.navlist > li:hover {
	border-bottom: 2px solid #002244;
}

.navlist .submenu-container {
	display: none;
}

.navlist .strategy-menu:hover .strategy-submenu {
	display: flex;
}

.navlist .program-menu:hover .program-submenu {
	display: flex;
}

.navlist .impact-menu:hover .impact-submenu {
	display: flex;
}

.navlist li:hover .submenu-container {
	padding-top: 15px;
	list-style-type: none;
	/* display: flex; */
	flex-direction: column;
	position: absolute;
	width: 300px;
	background: transparent;
	left: -100px;
}

.navlist li:hover .submenu-container > ul {
	box-shadow: 0px 2px 8px rgba(123, 123, 123, 0.32);
	list-style-type: none;
}

.navlist li:hover .submenu-container > ul > li {
	padding: 12px;
	box-shadow: 0px 1px 3px rgba(123, 123, 123, 0.45);
	cursor: pointer;
	background-color: #fff;
}

.subbar {
	height: 64px;
	width: 100% !important;
	background-color: #002244;
	margin-top: 90px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding-left: 115px;
	padding-right: 104px;
}

.subbar #wbg-logo {
	width: 175px;
	height: 32px;
}

.subbar #nav-search-icon {
	margin-left: 19px;
	padding-left: 35px;
	border-left: 1px solid #CCCCCC;
}

.subbar .language-session {
	display: flex;
}

.subbar .language-session span {
	color: #fff;
	font-family: 'Open Sans', sans-serif;
	font-weight: 400;
	font-size: 16px;
}

.subbar .language-session span.selected {
	font-weight: 700;
}

.subbar .language-session span:nth-child(1) {
	padding-right: 16px;
	border-right: 1px solid white;
}

.subbar .language-session span:nth-child(2) {
	padding-left: 16px;
}

#admin-link {
	color: #787878;
	text-transform: uppercase;
	font-weight: 600;
	padding-right: 15px;
}


@media (max-width: 768px) { 
	.language-logout-section {
		display: none;
	}

	.language-logout-section-small {
		display: inherit;
	}
}

@media (min-width: 768px) {
	.language-logout-section-small {
		display: none !important;
	}
}

.submenu-container ul {
	padding-left: 0;
}

.menu-button {
	background: none;
	border: none;
	outline: none;
	cursor: pointer;
}

.side-menu-open {
	position: absolute;
	height: 100vh;
	width: 100vw;
	background-color: #00000087;
	left: 0;
	top: 0;
}

.side-menu {
	position: absolute;
	top: 0;
	right: 0;
	background: #fff;
	max-width: 100vw;
	width: 350px;
	height: 100%;
	text-align: left;
	padding: 1.3rem;
}

.side-menu-hidden {
	width: 0px;
	overflow: hidden;
	display: none;
}

.side-menu-hidden .side-menu {
	padding: 0 !important;
}
.side-menu-hidden .side-menu {
	width: 0px;
	overflow: hidden;
}

.side-menu div {
	display: flex;
	flex-direction: column;
	overflow-y: auto;
}

.side-menu span {
	color: #787878;
	margin-top: 1.3rem;
	border-bottom: 1px solid #b9b8b8;
}

.side-menu .menu-item {
	font-family: "Open Sans",sans-serif;
	cursor: pointer;
	color: #1A1A1A;
	background: transparent;
	border: 0;
	outline: 0;
	font-size: 18px;
	line-height: 24.51px;
	margin-top: 18px;
	text-align: left;
}

.side-menu .menu-item:hover {
	text-decoration: none;
	color: #1A1A1A;
}
