.image-upload-wrapper {
    display: flex;
    align-items: center;
    border: 1px dashed #1A1A1A;
    min-height: 200px;
    padding: 0 10px;
}

.description {
    display: flex;
    width: 100%;
    justify-content: center;
}

.description .upload-button {
    color: #0071BC;
    text-decoration: none;
    font-weight: 400;
    background: transparent;
    border: none;
    margin: 0 5px;
    font-size: 14px;
    line-height: 1.4;
    cursor: pointer;
}

.description .upload-button:hover {
    text-decoration: none;
}

.description span {
    color: #666666;
    font-weight: 400;
    font-size: 14px;
    white-space: nowrap;
}

input[type=file] {
    display: none !important;
}

.image-preview-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.image-preview-wrapper {
    position: relative;
}

.image-preview {
    display: flex;
    width: min-content;
    flex-direction: column;
    align-items: center;
    max-height: 135px;
}

.image-name {
    font-weight: 400;
    color: #0071BC;
    margin: 10px 0 0;
    text-align: center;
}

.image-preview-wrapper span {
    position: absolute;
    top: -15px;
    right: -15px;
}

.image-preview-wrapper span img {
    width: 35px;
    height: 35px;
    cursor: pointer;
}
