.backButton {
  margin-left: -24px;
  margin-bottom: 24px;
  display: inline-block;
}

.project-details-container {
  padding-left: 120px;
  padding-right: 120px;
}

.section {
  margin-bottom: 60px;
}

.section h5 {
  color: #1A1A1A;
  font-family: 'Andes';
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 32px;
  text-align: left;
  margin-bottom: 24px;
}

.section > p {
  color: #4D4D4D;
  font-family: 'Open Sans';
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
}

.indicatorTabWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 24px;
  margin-bottom: 32px;
}

.indicatorGridWrapper {
  margin-bottom: 32px;
}

.indicatorsGraph,
.indicatorsGauge {
  display: flex;
  flex: 1;
  gap: 24px;
  width: 100%;
  align-items: center;
}

.indicatorsGraph {
  flex: 2;
}

.indicatorsGaugeWrapper {
  flex-direction: column;
}

.indicatorsCopyWrapper {
  display: flex;
  flex: 1;
  gap: 24px;
  width: 100%;
  justify-content: space-between;
}

.indicatorsChartsWrapper {
  display: flex;
}

.gauge,
.graph {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100%;
  min-width: 100%;
  width: 100%;
  height: 100%;
}

.graph > div {
  width: 100%;
  height: 100%;
}

.graph > div > svg {
  min-width: 100%;
  min-height: 100%;
  width: 100%;
  height: 100%;
}

.projectDetailsBreadcrumb {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #454545;
  text-align: left;
  width: 100%;
  display: block;
}

.projectDetailsBreadcrumb strong {
  color: #009FDA;
  font-weight: 400;
}

.indicatorTableItem {
  display: flex;
  width: 100%;
  gap: 24px;
  padding: 12px 24px;
  border: 1px solid #e6e6e6;
  font-size: 16px;
  text-transform: uppercase;
  background-color: var(--color-primary);
  color: white;
  transition: var(--transition-all);
}

.indicatorTableItem p {
  margin: 0;
}

.indicatorTableItemEven {
  background-color: var(--color-table-even);
  color: var(--color-primary);
}

.indicatorTableItemOdd {
  background-color: var(--color-table-odd);
  color: var(--color-primary);
}

.indicatorTableItemTarget {
  background-color: var(--color-secondary);
}

.indicatorItem {
  margin-bottom: 20px;
  width: 100%;
  text-align: left;
  padding: 16px;
  background-color: #fff;
  color: #262626;
  transition: var(--transition-all);
  box-shadow: 0px 2px 8px 2px rgba(123, 123, 123, 0.28);
  font-family: 'Andes', sans-serif;
  font-weight: 400;
  font-size: 24px;
  line-height: 24px;
}

.indicatorItemLabel {
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  color: #002244;
  line-height: 19px;
  font-family: 'Open Sans', sans-serif;
}

.indicatorItemBase {
  background-color: #fff;
}

.indicatorItemTarget {
  background-color: #fff;
}

.indicatorItemOverTarget {
  background-color: #fff;
}

.indicatorItemOnCourse {
  background-color: #fff;
}

.status {
  position: relative;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: bold;
}

.activeProject {
  color: #00AB51;
}

.activeProject:before {
  content: '';
  position: absolute;
  left: -24px;
  top: 2px;
  width: 18px;
  height: 18px;
  border-radius: 100px;
  background-color: #00AB51;
}

.inactiveProject {
  color: #666;
}

.inactiveProject:before {
  content: '';
  position: absolute;
  left: -24px;
  top: 2px;
  width: 16px;
  height: 16px;
  border-radius: 100px;
  background-color: #666;
}

@media screen and (max-width: 768px) {
  .mapWrapper {
    max-width: 85vw;
  }
}
