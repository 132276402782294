.benchmarking-header {
	height: 1020px;
	box-shadow: 0 5px 9px rgba(0, 0, 0, 0.05);
	background-image: linear-gradient(180deg, #028294 0%, #002245 100%);
}

.benchmarking-header-content {
	margin-top: -730px;
	color: #ffffff;
	font-family: 'Open Sans', sans-serif;
}

.header-data-row {
	margin-top: 40px;
}

.data-break hr {
	width:25px;
	text-align:center;
	margin: 0 auto;
	margin-top: 8px;
	margin-bottom: 8px;
	border: 0;
	border-top: 2px solid #fff;
}

.data-group {
	height:225px;
}

#benchmarkingHeaderPeak {
	-webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    width: 70px;
    height: 70px;
    /*background-image: linear-gradient(-44deg, #1E455d 0%, #1E475e 10%);*/
    background-color: rgba(30, 70, 95, .99);
    top:975px;
    left: calc(50% - 35px);
    position: absolute;
    z-index: -1;
}

.add-button {
	background: transparent;
	border: 0;
    padding: 0;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    margin-top: 0px;
    margin-right: 8px;
}

.compare-text {
	text-transform: uppercase;
	font-size: 10px;
	font-weight: 700;
	letter-spacing: 2px;
	margin-top: 10px;
	margin-right: 15px;
}

.add-country-container {
	display: flex;
	float: right;
	padding-top: 10px;
}

.add-country-container:hover {
	cursor: pointer;
}

@media (max-height: 1024px) {
	.benchmarking-header {
		height: 750px;
		box-shadow: 0 5px 9px rgba(0, 0, 0, 0.05);
	}

	.benchmarking-header-content {
		margin-top: -600px;
		color: #ffffff;
		font-family: 'Open Sans', sans-serif;
	}

	.header-data-row {
		margin-top: 30px;
	}

	.data-break hr {
		width:25px;
		text-align:center;
		margin: 0 auto;
		margin-top: 7px;
		margin-bottom: 7px;
	}

	.data-group {
		height:165px;
	}

	#benchmarkingHeaderPeak {
		-webkit-transform: rotate(45deg);
	    -moz-transform: rotate(45deg);
	    -o-transform: rotate(45deg);
	    -ms-transform: rotate(45deg);
	    transform: rotate(45deg);
	    width: 70px;
	    height: 70px;
	    /*background-image: linear-gradient(-44deg, #1E455d 0%, #1E475e 10%);*/
	    background-color: rgba(30, 70, 95, .99);
	    top:705px;
	    left: calc(50% - 35px);
	    position: absolute;
	    z-index: -1;
	}

	.add-button {
		background: transparent;
		border: 0;
	    padding: 0;
	    -webkit-transform: rotate(45deg);
	    -moz-transform: rotate(45deg);
	    -o-transform: rotate(45deg);
	    -ms-transform: rotate(45deg);
	    transform: rotate(45deg);
	    margin-top: 0px;
	    margin-right: 15px;
		float:right;
	}

	.compare-text {
		text-transform: uppercase;
		font-size: 10px;
		font-weight: 700;
		letter-spacing: 2px;
		margin-top: 10px;
		margin-right: 8px;
		float:right;
	}

	.add-country-container {

	}
}

@media (max-height: 850px) {
	.benchmarking-header {
		height: 640px;
		box-shadow: 0 5px 9px rgba(0, 0, 0, 0.05);
	}

	.benchmarking-header-content {
		margin-top: -460px;
		color: #ffffff;
		font-family: 'Open Sans', sans-serif;
	}

	.header-data-row {
		margin-top: 20px;
	}

	.data-break hr {
		width:25px;
		text-align:center;
		margin: 0 auto;
		margin-top: 5px;
		margin-bottom: 5px;
	}

	.data-group {
		height:125px;
	}

	#benchmarkingHeaderPeak {
		-webkit-transform: rotate(45deg);
	    -moz-transform: rotate(45deg);
	    -o-transform: rotate(45deg);
	    -ms-transform: rotate(45deg);
	    transform: rotate(45deg);
	    width: 70px;
	    height: 70px;
	    /*background-image: linear-gradient(-44deg, #1E455d 0%, #1E475e 10%);*/
	    background-color: rgba(30, 70, 95, .99);
	    top:595px;
	    left: calc(50% - 35px);
	    position: absolute;
	    z-index: -1;
	}

	.add-button {
		background: transparent;
		border: 0;
	    padding: 0;
	    -webkit-transform: rotate(45deg);
	    -moz-transform: rotate(45deg);
	    -o-transform: rotate(45deg);
	    -ms-transform: rotate(45deg);
	    transform: rotate(45deg);
	    margin-top: 0px;
	    margin-right: 8px;
		float:right;
	}

	.compare-text {
		text-transform: uppercase;
		font-size: 10px;
		font-weight: 700;
		letter-spacing: 2px;
		margin-top: 10px;
		margin-right: 8px;
		float:right;
	}

	.add-country-container {

	}
}



.header-country-name {
	text-transform: uppercase;
	color: #ffffff;
	font-family: 'Andes',Helvetica, Arial, Verdana, Tahoma, sans-serif;
}


.header-data-label-container {
	position: relative;
	height: 50px;
}

.header-data-label {
	text-transform: uppercase;
	font-size: 12px;
	font-weight: 700;
	bottom: 3px;
	position: absolute;
	width: 100%;
}

.header-data-value {
	font-weight: bolder;
	font-size: 30px;
	margin-top: -2px;
	text-transform: uppercase;
}


@media (max-width: 600px) {
	.header-data-label-container {
		position: relative;
		height: 45px;
	}

	.header-data-label {
		text-transform: uppercase;
		font-size: 11px;
		font-weight: 700;
		bottom: 3px;
		position: absolute;
		width: 100%;
	}

	.header-data-value {
		font-weight: bolder;
		font-size: 27px;
		margin-top: -2px;
		text-transform: uppercase;
	}
}

@media (max-width: 450px) {
	.header-data-label-container {
		position: relative;
		height: 45px;
	}

	.header-data-label {
		text-transform: uppercase;
		font-size: 10px;
		font-weight: 700;
		bottom: 3px;
		position: absolute;
		width: 100%;
	}

	.header-data-value {
		font-weight: bolder;
		font-size: 24px;
		margin-top: -2px;
		text-transform: uppercase;
	}

	.add-country-container {

	}
}

.export-button {
	margin-top: 50px;
	width: 161px;
	height: 45px;
	border-radius: 3px;
	border: 2px solid #fff;
	text-transform: uppercase;
	font-size: 12px;
	font-weight: 900;
	letter-spacing: 2px;
	line-height: 22px;
	background-color:transparent;
}



.header-flag-icon {
	font-size: 80px;
	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.23);
	/*border: 2px solid #fff;*/
	border-radius: 1px;
	height: 76px;
	width: 100px !important;
}


.export-icon {
	margin-top: -3px;
}

@media (max-width: 850px) {
	
.bench-row {
	flex-direction: column;
}
}

