/*reorganization*/

#no-country-results {
	width: 300px;
	height: 19px;
	color: #333;
	font-family: 'Open Sans', sans-serif;
	font-size: 14px;
	letter-spacing: 1.75px;
	line-height: 19px;
	position: absolute;
	left: 234px;
	text-align: center;
	top: 550px;
}

/*position*/
#country-modal {
	overflow: scroll !important;
}
#country-modal, #country-modal > .modal-dialog, #country-modal > .modal-dialog > .modal-content { 
	height: 100%;
	max-height: 700px;
	/*top: calc(50% - 350px);*/
	width: 768px;
	padding-left: 0px !important;
	background-color: #fff;
}
#country-modal > .modal-dialog > .modal-content {
	box-shadow: none;
	border: none;
}

#just-br-button {
	width: 256px;
	left: 255px;
}
#compare-button {
	width: 130px;
	left: 318px;
}

.country-buttons#compare-button, .country-buttons#just-br-button {
	font-size: 12px;
	font-weight: 900;
	letter-spacing: 2px;
	line-height: 44px;
	height: 45px;
	top: 275px;
}

.one-country > .flag-icon {
	height: 37px;
	top: 162px;
	width: 50px;
	left: 358px;
}
#one-country-close {
	height: 12px;
	top: 134px;
	width: 12px;
	left: 378px;
}
#one-country-name {
	height: 22px;
	width: 188px;
	font-size: 11px;
	font-weight: 700;
	letter-spacing: 2px;
	line-height: 22px;
	top: 213px;
	left: 289px;
}

.two-country-one > .flag-icon {
	height: 37px;
	top: 162px;
	width: 50px;
	left: 248px;
}
#two-country-one-close {
	height: 12px;
	top: 134px;
	width: 12px;
	left: 268px;
}
#two-country-one-name {
	height: 22px;
	width: 188px;
	font-size: 11px;
	font-weight: 700;
	letter-spacing: 2px;
	line-height: 22px;
	top: 213px;
	left: 181px;
}

#two-country-divider {
	top: 141px;
	height: 100px;
	left: 383px;
	width: 2px;
}

.two-country-two > .flag-icon {
	height: 37px;
	top: 162px;
	width: 50px;
	left: 479px;
}
#two-country-two-close {
	height: 12px;
	top: 134px;
	width: 12px;
	left: 497px;
}
#two-country-two-name {
	height: 22px;
	width: 188px;
	font-size: 11px;
	font-weight: 700;
	letter-spacing: 2px;
	line-height: 22px;
	top: 213px;
	left: 411px;
}

/*non-position*/
#country-modal, #country-modal > .modal-dialog, #country-modal > .modal-dialog > .modal-content { 
	display: flex;
	margin: 0 auto;
	border-radius: 0px;
	z-index: 10000;
}
/*#country-modal > .modal-dialog > .modal-content {
	background-image: url('./img/modal-overlay.png'), url('./img/modal-bg.png');
}*/

.country-buttons#compare-button, .country-buttons#just-br-button {
	text-align: center;
	/*border-radius: 3px;*/
	border: 2px solid #ec553a;
	background: transparent;
	color: #333;
	font-family: 'Open Sans', sans-serif;
	text-transform: uppercase;
	position: absolute;
}
#compare-button:hover, #just-br-button:hover {
	text-decoration: none;
	background-color: #cd2c0f;
	border: 2px solid transparent;
	color: #fff;
}

.one-country > .flag-icon {
	position: absolute;
	border: 1px solid #e4e4e4;
}
#one-country-close {
	position: absolute;
}
#one-country-close {
	cursor: pointer;
}
#one-country-name {
	color: #333;
	font-family: 'Open Sans', sans-serif;
	text-transform: uppercase;
	position: absolute;
	text-align: center;
}

.two-country-one > .flag-icon {
	position: absolute;
	border: 1px solid #e4e4e4;
}
#two-country-one-close {
	position: absolute;
}
#two-country-one-close {
	cursor: pointer;
}
#two-country-one-name {
	color: #333;
	font-family: 'Open Sans', sans-serif;
	text-transform: uppercase;
	position: absolute;
	text-align: center;
}

#two-country-divider {
	position: absolute;
}

.two-country-two > .flag-icon {
	position: absolute;
	border: 1px solid #e4e4e4;
}
#two-country-two-close {
	position: absolute;;
}
#two-country-two-close {
	cursor: pointer;
}
#two-country-two-name {
	color: #333;
	font-family: 'Open Sans', sans-serif;
	text-transform: uppercase;
	position: absolute;
	text-align: center;
}


/* still needs processing */


#modal-back-bg {
	position: absolute;
	left: 0px;
	top: 0px;
	z-index: 10;
}
#modal-back {
	position: absolute;
	left: 11px;
	top: 41px;
	z-index: 15;
}
#modal-back:hover {
	cursor: pointer;
}

#country-modal-close {
	position: absolute;
	left: 700px;
	top: 41px;
	z-index: 15;
}
#country-modal-close:hover {
	cursor: pointer;
}

#add-title {
	position: absolute;
	left: 210px;
	top: 30px;
	z-index: 15;
	text-align: center;
	width: 350px;
	height: 60px;
	color: #333;
	font-family: 'Andes',Helvetica, Arial, Verdana, Tahoma, sans-serif;
	font-size: 22px;
	font-weight: 900;
	letter-spacing: 2px;
	line-height: 30px;
	text-transform: uppercase;
}
#add-line {
	position: absolute;
	left: 350px;
	top: 75px;
	z-index: 15;
	width: 60px;
	border: 2px solid #ec553a;
}

#alpha-filters > .btn {
	font-family: 'Open Sans', sans-serif;
	font-size: 14px;
	font-weight: 700;
	letter-spacing: 1.27px;
	line-height: 24px;
	text-transform: uppercase;
	color: #868383;
	border: none;
	border-radius: 0px;
	padding: 0px;
	top: 333px;
	position: absolute;
	z-index: 15;
	background: transparent;
}
#alpha-filters > .btn:hover,  
#alpha-filters > .btn:focus, 
#alpha-filters > .btn:active {
	border: none;
	background: transparent;
	box-shadow: none;
}

.alpha-filter:hover {
	cursor: pointer;
}
.alpha-filter.selected {
	color: #333 !important;
}
#all-alpha {
	left: 149px;
}
#ae {
	left: 223px;
}
#fj {
	left: 297px;
}
#kp {
	left: 371px;
}
#qu {
	left: 445px;
}
#vz {
	left: 519px;
}

#modal-magnify {
	position: absolute;
	left: 594px;
	top: 363px;
	z-index: 15;
	cursor: pointer;
}

#category-filters {
	position: absolute;
	top: 412px;
}
#category-filters > .btn {
	height: 34px;
	color: #989595;
	font-family: 'Open Sans', sans-serif;
	font-size: 13px;
	font-weight: 700;
	line-height: 17px;
	border: none;
	border-radius: 0px;
	padding: 0px;
	position: absolute;
	z-index: 15;
	background: transparent;
	/*background-color: rgb(255, 255, 255, 0.0);*/
	text-align: left;
	overflow: visible;
}
#category-filters > .btn:hover,  
#category-filters > .btn:focus, 
#category-filters > .btn:active {
	border: none;
	background: transparent;
	/*background-color: rgb(255, 255, 255, 0.0);*/
	box-shadow: none;
	overflow: visible;
}
.category-filter:hover {
	cursor: pointer;
}
.category-filter.selected {
	color: #333 !important;
}
/*#all {
	width: 83px;
	left: 44px;
}*/
#top-oil-prod {
	width: 102px;
	left: 150px;
}
#top-oil-dep {
	width: 102px;
	left: 294px;
}
#gulf-coop {
	width: 50px;
	left: 437px;
}
#high-inc {
	width: 68px;
	left: 523px;
}
#top-trade {
	width: 97px;
	left: 616px;
}

#countriesScrollbar {
	position: absolute !important;
	top: 475px;
	left: 18px;
}
#countriesScrollbar > div:nth-child(1) {
/*	overflow: hidden;*/
/*	margin-right: -100px !important;
	margin-bottom: -100px !important;*/
}
.track-horizontal {
	height: 7px !important;
	bottom: 2px;
	left: 2px;
	border-radius: 3px;
	background: #ffffff;
	right: 2px;
}
.thumb-horizontal {
	height: 4px !important;
	cursor: pointer;
	border-radius: inherit;
	background-color: #ec553a;
	top: 1px;
}
.countries {
	display: flex;
}
.country {
    flex-shrink: 0;
    width: calc(25% - 12px);
	height: 165px;
	box-shadow: 0 12px 23px rgba(0, 0, 0, 0.1);
	border: 1px solid #e4e4e4;
	background-color: #ffffff;
	margin: 6px;
    align-items: center;
    justify-content: center;

    color: #333;
	font-family: 'Open Sans', sans-serif;
	font-size: 13px;
	font-weight: 700;
	letter-spacing: 2.36px;
	line-height: 15px;
	text-transform: uppercase;
	text-align: center;
}
.country:hover {
	cursor: pointer;
}
.country.selected {
	border: 5px solid #ec553a;
}
.country > div.flag-icon {
	width: 50px;
	height: 37px;
	border: 1px solid #e4e4e4;
	margin-top: 40px;
	margin-bottom: 13px;
}

.country.inactive {
	opacity: 0.5;
}
.country.inactive:hover {
	cursor: default;
}

.selection-text {
	width: 347px;
	height: 50px;
	color: #333;
	font-family: 'Open Sans', sans-serif;
	font-size: 15px;
	font-weight: 400;
	letter-spacing: -0.13px;
	line-height: 25px;
	text-align: center;
	position: absolute;
	top: 169px;
	left: 210px;
}

#left {
	position: absolute;
	top: 545px;
	left: 2px;
}

#right {
	position: absolute;
	top: 545px;
	left: 735px;
}

#left:hover, #right:hover {
	cursor: pointer;
}

#country-filter {
	width: 486px;
	height: 41px;
	/*border-radius: 4px;*/
	border: 2px solid #ec553a;
	background-color: #ffffff;
	position: absolute;
	top: 350px;
	left: 141px;

	color: #333;
	font-family: 'Open Sans', sans-serif;
	font-size: 13px;
	font-weight: 700;
	line-height: 17px;
	padding-left: 30px;
	padding-right: 55px;
}
#country-filter:focus, #country-filter:hover {
	box-shadow: 0 2px 14px rgba(0, 0, 0, 0.11);
}

#country-filter-close {
	position: absolute;
	top: 363px;
	left: 594px;
}
#country-filter-close:hover {
	cursor: pointer;	
}

.no-countries > .country {
	margin-left: calc(50% - 80px);
}

@media only screen and (min-width : 992px) and (min-height: 992px) {
	#modal-magnify {
		top: 348px !important;
	}
	#country-modal, #country-modal > .modal-dialog, #country-modal > .modal-dialog > .modal-content { 
		height: 100%;
		max-height: 1000px;
		/*top: calc(50% - 500px);*/
		width: 818px;
		display: flex;
		margin: 0 auto;
		border-radius: 0px;
		z-index: 10000;
	}
	#country-modal > .modal-dialog > .modal-content {
		background-size: 818px 1000px;
	}

	#country-options-section {
		position: absolute;
    	top: 150px;
    	left: 30px;
	}

	#country-modal-close {
    	left: 735px;
    	top: 50px;
	}

	#left {
		left: -20px;
	}
	#right {
		left: 760px;
	}
}

