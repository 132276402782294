@media (max-width: 850px) {
	.welcome-top, .welcome-base {
		padding-left: 80px;
		padding-right: 80px;
	}

    .welcome-top-top {
        flex-direction: column;
    }

    .welcome-base {
        flex-direction: column;
    }

    .welcome-footer-about {
        width: auto !important;
    }

    .welcome-footer-country {
        margin-top: 10px;
    }

    .welcome-top-bottom-expanded-content {
        flex-direction: column;
    }
}

@media (max-width: 600px) {
	.welcome-top, .welcome-base {
		padding-left: 20px !important;
		padding-right: 20px !important;
	}

    .welcome-base div {
        flex-direction: column;
    }

    .links.separator {
        border: none !important;
    }
}
.welcome-footer {
    display: contents;
    box-shadow: 0 5px 9px rgba(0, 0, 0, 0.05);
    /*background-image: linear-gradient(-44deg, #1e294e 0%, #1e7677 100%);*/
    position: relative;
    /*bottom: -50px;*/
    z-index: 100;
    font-family: 'Open Sans', sans-serif; 
}

.welcome-top-social {
    padding-bottom: 10px;
}

.social-logo {
    margin-right: 15px;
    height: 25px !important;
    width: 25px !important;
}

.welcome-top {
    background: #002244;
    width: 100%;
    padding-top: 45px;
    padding-left: 120px;
    padding-right: 120px;
    padding-bottom: 62px;
    color: #FFFFFF;
    display: flex;
    flex-direction: column;
}

.welcome-top-top {
    display: flex;
    padding-bottom: 60px;
}

.welcome-top-bottom {
    border-top: 1px solid #053657;
    padding-top: 19px;
}

.welcome-top-bottom-header {
    display: flex;
    justify-content: space-between;
}

.welcome-top-bottom-header .icon-flipped {
  transform: scaleY(-1);
}

.welcome-top-bottom-expanded-content {
    margin-top: 16px;
    display: flex;
    justify-content: space-between;
}

.welcome-top-bottom-expanded-content .connect-social-media {
    text-align: left;
}

.welcome-top-bottom-expanded-content .connect-social-media span {
    color: #FFFFFF;
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 25px;
    text-align: left;
}

.welcome-footer-about {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 50%;
}

.welcome-footer-about span {
    font-family: 'Open Sans', sans-serif;
    font-size: 18px;
    line-height: 24.51px;
    font-weight: 700;
    margin: 0;
    margin-bottom: 16px;
}

.welcome-footer-country {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-grow: 1;
    gap: 12px;
}

.welcome-footer-country p {
    color: #FAFAFA;
    font-family: 'Open Sans', sans-serif;
    font-size: 18px;
    font-weight: 700;
    margin: 0;
    margin-bottom: 8px;
}

.welcome-footer-country span {
    color: #F2F2F2;
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    font-weight: 400;
}

.welcome-base {
    padding: 0px;
    padding-top: 6px;
    padding-bottom: 6px;
    padding-left: 120px;
    padding-right: 120px;
    background-color: #1A1A1A;
    margin-left: 0px;
    margin-right: 0px;
    box-sizing: border-box;
    line-height: 40px;
    color: #B4B4B4 !important;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.welcome-base img {
    margin-right: 8px;
}

.welcome-base .links, .welcome-base .links a {
    color: #B4B4B4; 
    font-size: 16px;
    line-height: 16px;
    font-weight: 600;
    text-transform: uppercase;
    /* margin-left: 8px; */
    padding-left: 8px;
    text-align: left;
    font-family: 'Open Sans', sans-serif;
}

.welcome-base .copyright-text {
    color: #B4B4B4;
    font-size: 16px !important;
    line-height: 21.79px;
    font-family: 'Open Sans', sans-serif;
}

.blue-button {
    color: #fff !important;
    background: #0071bc !important;
    font-size: 8px !important;
    border: 0px !important;
    border-radius: 0px !important;
    text-transform: uppercase !important;
    line-height: 15px !important;
    font-weight: 800 !important;
    letter-spacing: 1.2px;
}

.blue-button > a {
    color: #fff !important;
    text-decoration: none;
}

.links:hover {
    cursor: pointer;
}

.links.separator {
    border-right: 1px solid #B4B4B4;
    padding-right: 16px;
}

.links > a {
    color: #B4B4B4 !important;
    text-decoration: none !important;
    font-size: 15px;
    font-weight: 400;
}

.social-logo {
    position: relative;
    z-index: 2;
}

.fb-bg {
    background-color: rgb(24, 119, 242);
    width: 40px;
    height: 40px;
    display: inline-block;
    position: absolute;
    z-index: 1;
    border-radius: 100px;
    margin-top: 5px;
}

.twitter-bg {
    background-color: rgb(29, 161, 242);
    width: 40px;
    height: 40px;
    display: inline-block;
    position: absolute;
    z-index: 1;
    border-radius: 100px;
    margin-top: 5px;
}

.ig-background {
    width: 40px;
    height: 40px;
    background: white;
    display: inline-block;
    position: absolute;
    margin-top: 5px;
    border-radius: 20px;
}

.ig {
    position: relative;
    height: 28px !important;
    margin-left: 6px;
    margin-right: 22px;
}

@media (max-width: 768px) {

  .welcome-top-text {
    font-size: 14px;
  }
}
