#program-container {
	text-align: left;
	padding-top: 56px;
	padding-left: 120px;
	padding-right: 120px;
	padding-bottom: 72px;
}

#program-container h2 {
	font-family: 'Andes', sans-serif;
	color: #1A1A1A;
	font-size: 40px;
	line-height: 40px;
	font-weight: 400;
	margin: 0px;
}

.program-row {
	margin-top: 25px;
}

#program-summary p, #program-summary a {
	font-family: 'Open Sans', sans-serif;
	font-size: 18px;
	line-height: 25px !important;
	font-weight: 400;
	color: #4D4D4D !important;
	margin: 0px;
}

.program-map-content {
	padding-left: 120px;
	padding-right: 120px;
}

#program-container .divider {
	width: 82px;
	height: 0px;
	border-bottom: 2px solid #CDE7F9;
  margin-top: 5px;
	margin-bottom: 40px;
}

#focus-dropdown, #bench-dropdown {
	color: #333333;
    font-size: 14px;
    font-weight: 600;
    width: 100%;
}

#focus-dropdown > .caret, #bench-dropdown > .caret {
	color: #0071bc;
}

.program-sentiment-button {
	border-radius: 25px !important;
	padding: 10px 20px;
}

#projects-list {
	width: 100%;
	background: #fff;
	padding: 40px;
	border-radius: 5px;
	border: 2px solid #ccc;
	height: 700px;
	overflow-y: scroll;
}

.projects-summary {
	display: flex;
}

.program-num {
	color: #0071bc;
	font-size: 50px;
	font-weight: 700;
}

.projects-title {
	color: #002544;
	font-size: 14px;
	font-weight: 700;
	text-transform: uppercase;
	text-align: left;
	padding-top: 15px;
	padding-left: 15px;
}

.projects-divider {
	height: 20px;
    border-bottom: 2px solid #ccc;
	margin-bottom: 10px;
}

.project-details {
	text-align: left;
	padding-bottom: 15px;
}

.project-focus {
	color: #333333;
	font-size: 10px;
	font-weight: 400;
	line-height: 28px;
}

.project-title {
	color: #333333;
	font-size: 14px;
	font-weight: 700;
	line-height: 22px;
}

.project-buttons > .btn {
	display: none;
	background-color: #0071bc;
	border-radius: 25px;
	color: #fff;
	font-size: 10px;
	margin: 1px;
}

.project-details:hover > .project-buttons > .btn {
	display: inline-block;
}

.project-details:hover > .project-buttons > .btn:hover {
	color: #0071bc;
	border: 1px solid #0071bc;
	background-color: #fff;
}

.analytics {
	height: 300px;
	background-image: linear-gradient(244deg, #065662 0%, #00427c 100%);
	border-radius: 5px;
}

.analytics-text {
	color: #ffffff;
	font-size: 18px;
	font-weight: 700;
	text-transform: uppercase;
	width: 70%;
	margin-left: 15%;
	padding-top: 30px;
}

.analytics-icon {
	padding-top: 30px;
	padding-bottom: 30px;
}

.money {
	width: 100%;
	background: #fff;
	padding: 30px;
	border-radius: 5px;
	border: 2px solid #ccc;
	height: 300px;
	text-align: left;
}

.money-subtext {
	color: #333333;
	font-size: 12px;
	font-weight: 700;
	line-height: 14px;
	text-transform: uppercase;
}

.money-text {
	color: #333333;
	font-size: 15px;
	font-weight: 400;
	line-height: 26px;
}

.temp-progress-bar {
	width: 100%;
	padding: 10px 0px 10px 0px;
}

.project-link {
	text-align: left;
	line-height: 20px;
	cursor: pointer;
}

.project-link .fa {
	float: right;
	margin: 0;
	position: absolute;
    right: 5%;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}