 .lineGraphGrid > .tick line {
  stroke: #333333;
  stroke-opacity: 0.5;
  shape-rendering: crispEdges;
  stroke-width: 3px;
  transform: translate(10px);
}
.lineGraphGrid > .tick ~ .tick line {
  stroke: #333333;
  stroke-opacity: 0.2;
  shape-rendering: crispEdges;
  stroke-width: 3px;
  transform: translate(10px);
}

.lineGraphGrid path {
  stroke-width: 0;
}

.lineGraph text {
	  fill: #333333;
    font-family: 'Open Sans', sans-serif;
    font-size: 2em;
}
.lineGraph2 text {
    fill: #333333;
    font-family: 'Open Sans', sans-serif;
    font-size: 1.8em;
}
.lineGraph3 text {
    fill: #333333;
    font-family: 'Open Sans', sans-serif;
    font-size: 1.6em;
}
.lineGraph4 text {
    fill: #333333;
    font-family: 'Open Sans', sans-serif;
    font-size: 1.4em;
}
.lineGraph5 text {
    fill: #333333;
    font-family: 'Open Sans', sans-serif;
    font-size: 1.2em;
}
.lineGraph6 text {
    fill: #333333;
    font-family: 'Open Sans', sans-serif;
    font-size: 1em;
}
.lineGraph7 text {
    fill: #333333;
    font-family: 'Open Sans', sans-serif;
    font-size: 0.8em;
}

.lineGraphAxis path {
	display: none;
}

.lineGraphAxis .tick line {
	display: none;
}
.lineGraphTargetText {
	font-weight: 700;
  font-size: 1.5em !important;
}
.lineGraphTargetText2 {
  font-weight: 700;
  font-size: 1.4em !important;
}
.lineGraphTargetText3 {
  font-weight: 700;
  font-size: 1.3em !important;
}
.lineGraphTargetText4 {
  font-weight: 700;
  font-size: 1.2em !important;
}
.lineGraphTargetText5 {
  font-weight: 700;
  font-size: 1.1em !important;
}
.lineGraphTargetText6 {
  font-weight: 700;
  font-size: 1em !important;
}
.lineGraphTargetText7 {
  font-weight: 700;
  font-size: 0.9em !important;
}