#projects-container {
	padding-top: 56px;
	padding-left: 120px;	
	padding-right: 120px;	
}

@media (max-width: 800px) {
	#projects-container {
		padding-top: 56px;
		padding-left: 5px;	
		padding-right: 5px;	
	}
}

@media (max-width: 1000px) {
	.projects-header {
		flex-direction: column;
	}

	.projects-title-divider {
		border: none !important;
		padding: 10px;
	}
}

@media (max-width: 450px) {
	.projects-search-filter {
		max-width: 60vw !important;
	}
}

.edit-screen-footer {
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 60px;
	background-color: #1A1A1A;
	padding-left: 120px;
	padding-right: 120px;
}

#projects-container h2 {
	color: #002244;
	font-family: 'Andes', sans-serif;
	font-size: 2.5rem;
	line-height: normal;
	font-weight: 400;
	margin: 0px;
	text-align: left;
}

#projects-container > .divider, .projects-title-divider {
	width: 82px;
	height: 0px;
	border-bottom: 2px solid #CDE7F9;
	margin-top: 8px;
}

#projects-container .projects-pagination {
	width: 100%;
	display: flex;
	justify-content: flex-end;
	gap: 20px;
	margin-top: 43px;
	margin-bottom: 148px;
}

#projects-container .projects-search-filter {
	background-color: white;
	border: 0px;
	box-shadow: 0px 0px 0px #CBDAFC;
	padding: 11px;
	height: 100%;
	width: 340px;
	height: 44px;
	margin-right: 16px; 
	padding-left: 50px;
}

#projects-container .projects-search-filter::placeholder {
	color: #CCCCCC !important;
	font-size: 14px;
	font-weight: 400;
	line-height: normal;
	font-family: 'Open Sans', sans-serif;
}

#projects-container .projects-current-page {
	color: #0071BC;
	font-size: 0.8rem;
	font-weight: 400;
	font-family: 'Open Sans', sans-serif;
	line-height: normal;
}

.projects-next-button, .projects-previous-button {
	color:#0071BC;
	font-family: 'Open Sans', sans-serif;
	font-size: 0.8rem;
	line-height: normal;
	font-weight: 400;
}

.projects-next-button.disabled, .projects-previous-button.disabled {
	color: #B4B4B4 !important;
}

.text-left {
	text-align: left;
}

#projects-container .focus-areas-cards {
  margin-top: 56px;
  overflow-x: auto;
  padding-bottom: 1.3rem;

}

.projects-cards-container {
	margin-top: 44px;
	display: flex;
	flex-direction: column;
	gap: 2rem;
}

#filters-title {
	text-transform: uppercase;
	text-align: left;
	color: #333333;
	/*font-family: "Open Sans Semi Bold";*/
	font-size: 0.6rem;
	font-weight: 600;
	
}

#filters-row {
	width: 100%;
}

.filters-span {
	display: flex !important;
	align-items: center !important;
	padding-left: 3% !important;
	text-align: left !important;
	height: 100px;
	/*width: 200px !important;*/
	border: none !important;
}

.filters-text {
	padding-left: 15px;
}

#filter-1, #filter-2 {
	border: none;
}

.filter-label {
	color: #0071bc;
	/*font-family: "Open Sans Semi Bold";*/
	font-size: 0.7rem;
	font-weight: 600;
}

.filter-title {
	color: #333333;
	/*font-family: "Open Sans Semi Bold";*/
	font-size: 0.7rem;
	font-weight: 600;
	white-space: normal;
}

.filters-span.active {
	background: #028294 !important;
	border-radius: 3px !important;
}

.filters-span.active > .filters-text > .filter-label, .filters-span.active > .filters-text > .filter-title {
	color: white !important;
}

.filters-span:hover {
	cursor: pointer;
}

.filters-icon > img {
	width: 65px;
	height: 65px;
}

.proj-icon {
	position: relative;
	display: inline-block;
	font-size: 50px;
	line-height: 80px;
	color: #058a8f;
}

.proj-icon-white {
	position: relative;
	display: inline-block;
	font-size: 50px;
	line-height: 80px;
	color: #fff;
}

/* .project-container {
	text-align: left;
	background: #fff;
	border: 1px solid lightgray;
	border-radius: 5px;
	margin-top: 20px;
	height: 400px;
} */

.project-text {
	padding: 15px;
}

.project-small {
	color: #333333;
	/*font-family: "Open Sans", Helvetica, Arial, Verdana, Tahoma, sans-serif;*/
	font-size: 0.7rem;
	font-weight: 400;
	line-height: 28px;
}

.project-org {
	/*text-align: right;*/
}

.project-org > img {
	width: 200px;
}

/* .project-title > a {
	color: #333333 !important; */
	/*font-family: "Open Sans Semi Bold";*/
	/* font-size: 20px;
	font-weight: 600;
	line-height: 32px;
	font-family: 'Andes',Helvetica, Arial, Verdana, Tahoma, sans-serif;
} */

/* .project-title > a:hover {
	color: #0071bc !important;
	text-decoration: none !important;
}

.project-description {
	color: #333333;
	font-size: 16px;
	font-weight: 400;
	line-height: 30px;
}

.project-links {
	padding-bottom: 15px;
}

.project-link {
	padding: 15px;
	font-size: 0.7rem;
	font-weight: 600;
	line-height: 30px;
} */

.readmore-button {
	color: #333 !important;
    background: #4cbb88 !important;
    font-size: 0.6rem !important;
    border: 0px !important;
    border-radius: 0px !important;
    text-transform: uppercase !important;
    line-height: 16px !important;
    font-weight: 800 !important;
    letter-spacing: 1.2px;
}

.impact {
	color: #0071bc;
	/*font-family: "Open Sans Semi Bold";*/
	font-size: 0.7rem;
	font-weight: 600;
	line-height: 30px;
}

.add-data {
	color: #ea5228;
	/*font-family: "Open Sans Semi Bold";*/
	font-size: 0.7rem;
	font-weight: 600;
	line-height: 30px;
}

.add-data-upload {
	padding-right: 10px;
}

.impact:hover, .add-data:hover {
	cursor: pointer;
}

.criteria-icon-main-page {
	font-size: 2rem;
	color: #ec553a;
	padding: 5px;
}

.project-is-covid {
	font-size: 2rem;
	float: right;
	color: #333;
}

.add-project {
	margin-top: 30px;
}

/* Project details */

#project-details-container {
	padding-left: 120px;
	padding-right: 120px;
	padding-top: 16px;
}

#project-details-container h2 {
	color: #1A1A1A;
	font-family: 'Andes', sans-serif;
	font-weight: 400;
	font-size: 2.5rem;
	line-height: normal;
	text-align: left;
	margin: 0px;
	margin-top: 21px;
}

#project-details-container h3 {
	color: #000000;
	margin: 0px;
	margin-top: 40px;
	margin-bottom: 32px;
	font-size: 1.1rem !important;
	line-height: normal;
	font-weight: 400;
	font-family: 'Andes', sans-serif;
	text-align: left;
}

#project-details-container .divider {
	width: 82px;
	border-bottom: 2px solid #CDE7F9;
	margin-top: 8px;
}

.finance-page {
	margin: auto;
}

.focus-desc {
	color: #4d4d4d;
	font-size: 18px;
	font-weight: 400;
	line-height: normal;
	text-align: left;
	padding-bottom: 20px;
}

#focus-columns {
	/*width: 80% !important;*/
	/*margin-left: 10% !important;*/
}

.focus-column {
	/* Have same format as other sections for smaller screen sizes */
	margin-top: 30px;

	/* Styling for all sizes */
	border-radius: 5px;
	height: 300px;
	background: #fff;
	text-align: center;
	position: relative;
	z-index: 1
}

.focus-column:hover {
	cursor: pointer;
}

.focus-column.active {
	/*background-image: linear-gradient(206deg, #006169 0%, #006169 100%);*/
	background: #028294;
}

.focus-icon {
	position: relative;
	top: 0;
	left: 0;
	height: 100px;
	width: 100px;
	margin: 0 auto;
	/*border-radius: 100px;*/
	/*background-image: linear-gradient(-135deg, #065662 0%, #0071bc 100%);*/
}

.img-circle {
	position: relative;
	top: 0;
	left: 0;
	height: 100px;
}

.img-icon {
	position: relative;
	top: -100px;
	display: inline-block;
	font-size: 50px;
	line-height: 100px;
	color: #058a8f;
}

.focus-text {
	/*width: 80%;*/
	/*margin-left: 10%;*/
	margin-top: 10%;
}

.focus-label {
	padding-top: 10px;
	color: #0071bc;
	font-size: 10px;
	font-weight: 600;
}

.focus-button {
	margin: 5px;
	position: relative;
	z-index: 100;
}

.focus-caret {
	/* color: #4cbb88; */
}

.focus-column.active > .focus-text > .focus-label, .focus-column.active > .focus-text > .focus-title {
	color: #fff;
}

.focus-column.active > .focus-text > .focus-caret {
	color: transparent;
}

.active-strategy-caret {
	color: #028294 !important;
	margin-top: -11px;
    display: block;
}

.focus-title {
	font-size: 10px;
	width: 96%;
}

#focus-desc {
	text-align: left;
}

#focus-desc-title {
	font-size: 2rem;
	line-height: normal;
	font-weight: 400;
	color: #1A1A1A;
	font-family: 'Andes', sans-serif;
	margin-top: 39px;
	margin-bottom: 24px;
}

.focus-paragraph {
	font-family: 'Open Sans', sans-serif;
	line-height: normal;
	color: #4D4D4D;
	font-size: 0.9rem;
	margin-bottom: 18px;
}

#objective-columns {
	display: flex;
	flex-direction: column;
	gap: 24px;
	margin-top: 18px;
}

.objective-column {
	border-radius: 5px;
	height: 300px;
	/*background-image: linear-gradient(206deg, #006169 0%, #006169 100%);*/
	background: #028294;
	text-align: left;
}

.objective-text {
	width: 80%;
	padding-left: 15%;
	padding-top: 15%;
	height: 100%;
	margin-top: 5%;
}

.objective-title {
	color: #fff;
	font-size: 10px;
}

.objective-description {
	/* Style for "Increase e" */
	color: #fff;
	font-size: 10px;
	font-weight: 600;
	line-height: normal;
}

.objective-buttons {
	text-align: center;
	margin-top: 5%;
}

.yellow-strat-button {
    color: #333 !important;
    /* background: #4cbb88 !important; */
    font-size: 10px !important;
    border: 0px !important;
    border-radius: 0px !important;
    text-transform: uppercase !important;
    line-height: 15px !important;
    font-weight: 800 !important;
    letter-spacing: 1.2px;
    z-index: 1000;
}

/*.yellow-strat-button:hover {
	color: #4cbb88 !important;
	background: #333 !important;
}*/

.yellow-outline-strat-button {
    color: #fff !important;
    background: transparent !important;
    font-size: 10px !important;
    border: 1px solid #4cbb88 !important;
    border-radius: 0px !important;
    text-transform: uppercase !important;
    line-height: 15px !important;
    font-weight: 800 !important;
    letter-spacing: 1.2px;
}

/*@media  (min-width : 768px) {
	#focus-columns {
		margin-top: 0px;
	}

	.focus-column {
		margin-top: -50px;
	}
}*/

#strategy-container {
	padding-top: 56px;
	padding-bottom: 56px;
	padding-left: 120px;
	padding-right: 120px;
	display: flex;
	flex-direction: column;
}

@media (max-width: 850px) {
	#strategy-container {
		padding-left: 80px;
		padding-right: 80px;
	}
}

@media (max-width: 600px) {
	#strategy-container {
		padding-left: 20px !important;
		padding-right: 20px !important;
	}
}

#strategy-container h2 {
	color: #1A1A1A;
	font-family: 'Andes', sans-serif;
	font-size: 2.5rem;
	line-height: normal;
	font-weight: 400;
	margin: 0px;
	margin-bottom: 6px;
	text-align: left;
}

#strategy-container .divider {
	width: 82px;
	height: 0px;
	border-bottom: 2px solid #CDE7F9;
	margin-bottom: 40px;
}

.focus-areas-cards {
	display: flex;
	gap: 1.3rem;
	overflow-x: auto;
	padding-bottom: 1.3rem;
}

.objective-card {
	padding: 24px;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 2px 8px 2px rgba(123, 123, 123, 0.32);
  width: 100%;
  align-items: flex-start;
  background-color: #fff !important;
}

.objective-card h1 {
  margin: 0px;
  color: #002244;
  font-family: 'Andes', sans-serif;
  font-size: 2rem;
  line-height: normal;
  font-weight: 400;
  text-align: left;
}

.objective-card span {
  margin: 0px;
  color: #1A1A1A;
  font-family: 'Open Sans', sans-serif;
  font-size: 0.8rem;
  line-height: normal;
  font-weight: 400;
  text-align: left;
  line-height: 21.79px;
}

.objective-card-footer {
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-top: 16px;
}


@media (max-width: 850px) {
	#strategy-container .project-title {
		flex-direction: column;
		align-items: flex-start !important;
	}

	#strategy-container .project-title span {
		margin-left: 1.3rem;
	}

	.other-details {
		flex-direction: column;
	}
}

@media (min-width: 600px) {
	.financial-row {
		grid-template-columns: 1fr 1fr 1fr 1fr;
	}
}