#story-container {
	padding-top: 56px;
	padding-bottom: 56px;
	padding-left: 120px;
	padding-right: 120px;
}


@media (max-width: 850px) {
	#story-container {
		padding-left: 40px;
		padding-right: 40px;
		flex-direction: column;
		align-items: flex-start !important;
	}

	#story-container div {
		flex-direction: column;
	}

	#story-container .story {
		overflow-y: auto;
	}

	#story-container .left-menu {
		flex-direction: row !important;
		overflow-x: auto;
	}

	#story-container .left-menu span {
		margin-right: 10px;
		min-width: fit-content !important;
	}
}

#story-container h2 {
	color: #002244;
	font-family: 'Andes', sans-serif;
	font-size: 40px;
	line-height: 40px;
	font-weight: 400;
	margin: 0px;
	margin-bottom: 6px;
	text-align: left;
}

#story-title .story-title {
	font-size: 32px !important;
	font-weight: 400;
	font-size: 32px;
	line-height: 32px;
	color: #1A1A1A;
}

.story-outside-title {
	color: #002244;
	font-family: 'Andes', sans-serif;
	font-size: 40px;
	line-height: 40px;
	font-weight: 400;
	margin: 0px;
	margin-bottom: 6px;
	text-align: left;
}

.story-divider {
	width: 82px;
	height: 0px;
	border-bottom: 2px solid #CDE7F9;
	margin-bottom: 40px;
}

#story-container .divider {
	width: 82px;
	height: 0px;
	border-bottom: 2px solid #CDE7F9;
	margin-bottom: 40px;
}

#story-container .left-menu {
	display: flex;
	flex-direction: column;
	min-width: 168px;
}

#story-container .left-menu span {
	color: #666666;
	font-family: 'Andes', sans-serif;
	font-weight: 400;
	font-size: 20px;
	margin-bottom: 24px;
	line-height: 20px;
	cursor: pointer;
	text-align: left;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

#story-container .left-menu span a {
	color: #4D4D4D;
	text-decoration: none;
	text-overflow: ellipsis;
}

#story-container .left-menu span a:hover {
	color: #000000;
}

#story-container .story {
	flex-grow: 1;
}

#story-container .historical-engagement-topic {
	font-family: 'Open Sans', sans-serif !important;
	font-size: 22px !important;
	font-weight: 400 !important;
	line-height: 30px !important;
	color: #1A1A1A !important;
}

#story-container .story h3 {
	margin: 0;
	margin-bottom: 24px;
	margin-top: 32px;
	font-family: 'Andes', sans-serif;
	text-align: left;
	color: #1A1A1A;
	font-size: 32px;
}

#story-container .story h3:first-of-type {
	margin-top: 0px !important;
}

#story-container .story span {
	display: block;
	text-align: left !important;
	font-family: 'Open Sans', sans-serif;
	font-size: 18px;
	color: #4D4D4D;
	line-height: 24.51px;
}

#story-container .story li .list {
	font-family: 'Open Sans', sans-serif;
	font-size: 18px;
	color: #4D4D4D;
	line-height: 24.51px;
	text-align: left;
	margin-left: 60px;
}

#story-container #tabs li {
	text-align: center !important;
}
#story-container .story img {
	margin-top: 35px;
}

.story {
	max-height: 80vh;
	position: relative;
}

.story-text {
	overflow-y: auto;
	height: 100%;
}

.story-gradient {
	width: 100%;
	height: 30%;
	bottom: 0;
	position: absolute;
	background-image: linear-gradient(rgba(255, 255, 0, 0), white);

}

.active {
	font-weight: 700 !important;
  }

.story-text img {
	max-width: 80%;
	margin-bottom: 1.3rem;
}

#story-container .story .story-text .photo-credit {
	font-size: 12px;
	text-align: center !important;
}

.story-text .iframe {
	height: 500px;
}

.story-text iframe {
	border: none;
	margin-top: 1.3rem;
}

/* width */
.story-text::-webkit-scrollbar {
	width: 10px;
  }
  
  /* Track */
  .story-text::-webkit-scrollbar-track {
	background: #f1f1f1;
  }
  
  /* Handle */
  .story-text::-webkit-scrollbar-thumb {
	background: #888;
  }
  
  /* Handle on hover */
  .story-text::-webkit-scrollbar-thumb:hover {
	background: #555;
  }

.story-text span {
	display: grid;
}

.story-text span img, .story-text span iframe {
	justify-self: center;
	margin-top: 10px;
	margin-bottom: 1.3rem;
}

.image-container {
	width: 100%;
	display: flex;
	justify-content: center;
	margin-top: 20px;
}
