#root, .App {
  height: 100%;
}

.mapboxgl-canvas {
	position: relative !important;
	height: 100%;
	width: 100%;
}

.mapboxgl-popup-content {
  width: 300px;
  max-height: 400px;
}

.image-content, .project-content, .subproject-content {
  overflow-y: auto;
  max-height: 380px;
}

.sub-projects .list-group-item i, .subproject-content .list-group-item i {
  float: right;
  padding-left: 10px;
}

.sub-projects .list-group-item:focus {
  outline: none;
}

.subproject-content {
  display: none;
}

.ssa-project {
  display: inline-flex !important;
  align-items: center; 
}

.back {
  font-size: 16px;
}

#map-container {
  width: 100%;
  padding-top: 15px;
  padding-bottom: 10px;
  height: 400px;
}

#covid-map-container {
  height: 100%;
  width: 100%;
}

#admToggle {
	position: absolute;
    top: 25px;
    left: 25px;
}

#caseToggle {
  position: absolute;
    top: 75px;
    left: 25px;
}

#map-container .legend, #covid-map-container .legend {
    background-color: #fff;
    border-radius: 3px;
    bottom: 17px;
    box-shadow: 0 1px 2px rgba(0,0,0,0.10);
    font: 12px/20px 'Helvetica Neue', Arial, Helvetica, sans-serif;
    padding: 10px;
    position: absolute;
    right: 25px;
    z-index: 1;
}

#program-container {
  margin-bottom: 20px;
}

#programMapContainer, #stateColumn {
  height: 600px;
}

.program-map, #stateColumn {
  
  border-radius: 5px;
  background: #fff;
  overflow: hidden;
  overflow-y: auto;
}

#program-container a {
    color: #cd2c0f;
}

.fa-color {
  width: 20px;
  height: 20px;
  margin-right: 5px;
  float: left;
}

.program-map {
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;
  border-radius: 5px;
}

.program-map .fa-menu {
  text-align: left;
  cursor:pointer;
  pointer-events: auto;
  margin-top: 5px;
}
  .program-map .fa-menu:hover {
    background-color: #edebeb;
  }

.program-map #all, .back {
  cursor: pointer;
  color: #cd2c0f;
}
  .program-map #all:hover, .back:hover {
    font-weight: 600;
  }

.program-map .theme-toggle-bg {
    width: 50px;
    height: 24px;
    border-radius: 100px;
    float: right;
}

.program-map .program-legend {
    background-color: #fff;
    border-radius: 3px;
    bottom: 17px;
    box-shadow: 0 1px 2px rgba(0,0,0,0.10);
    font: 12px/20px 'Helvetica Neue', Arial, Helvetica, sans-serif;
    padding: 10px;
    position: absolute;
    right: 10px;
    z-index: 1;
    width: 350px;
}

.program-map .program-legend.minimized {
  width: 100px;
}

.program-map .legend-section {
    margin-top: 10px;
    display: inline-block;
    width: 100%;
}

.program-map .legend h4 {
    margin: 0 0 10px;
    font-size: 14px;
}

.program-map .legend div span, #map-container .legend div span, #covid-map-container .legend div span {
    border-radius: 50%;
    display: inline-block;
    height: 10px;
    margin-right: 5px;
    width: 10px;
}

.program-map .state-div {
  display: inline-block;
  width: 100%;
  font-size: 12px;
  color: #333;
}

.program-map .state-name {
  
  float: left;
}
.program-map .state-value {
  float: right;
}

.program-map #legend {
  width: 315px;
  height: 100%;
  background-color: white;
  position: absolute;
  top: 0;
  left: 0;
  transition: left .2s;
  margin: 0;
  z-index: 10;
}

.program-map #legend.go-left {
  left: -315px;
}

.program-map #left-tabs, .program-map #tabs, .program-map #tabContent {
  height: 100%;
}

.program-map #tabs {
  background-color: #024;
  padding: 0 !important;
  width: 75px;
  float: left;
}

.program-map #tabs li a {
  color: white;
  padding: 20px 0 !important;
  outline: none;
  text-transform: uppercase;
}

.program-map #tabs li a:hover {
  color: white;
  background-color: #024;
}

.program-map #tabs li.active a {
  background-color: white;
  color: #024;
}

.program-map #tabs li a .tab-label {
  font-size: 10px;
  font-weight: 600;
}

.program-map #tabContent {
  width: 238px;
  float: right;
  overflow: hidden;
}

.program-map .tab-content {
  margin: 0 9px;
  overflow-y: auto;
  height: 100%;
}

.program-map .section-heading {
  text-align: left;
  color: #2f333b;
  font-size: 16px;
  font-weight: 700;
  border-bottom: 2px solid #dee3e8;
  padding: 10px 0;
  text-transform: uppercase;
}

.program-map .section-icon {
  float: left;
  margin-right: 10px;
}

.program-map .layer-section {
  font-size: 14px;
  font-weight: 700;
  text-align: left;
  padding: 10px 0;
  border-bottom: 1px solid #dee3e8;
}

.program-map .layer {
  font-size: 12px;
  font-weight: 300;
  text-align: left;
  padding: 10px 0;
  border-bottom: 1px solid #dee3e8;
  height: 35px;
  white-space: nowrap;
}

.program-map .layer-info {
      width: 15px;
    height: 15px;
  float: left;
  margin-right: 10px;
  font-size: 12px;
  color: #0071bc;
  cursor: pointer;
  border: 1px solid #636e7f;
    border-radius: 100%;
    padding: 0;
    /* color: #636e7f; */
    text-align: center;
    vertical-align: middle;
    padding-top: 1px;
    margin-top: 1px;
}

.program-map .category-info {
      width: 15px;
    height: 15px;
  margin-left: 10px;
  font-size: 12px;
  color: #0071bc;
  cursor: pointer;
  border: 1px solid #636e7f;
    border-radius: 100%;
    padding: 0;
    /* color: #636e7f; */
    text-align: center;
    vertical-align: middle;
    padding-top: 1px;
    margin-top: 1px;
}

.program-map .layer-info svg, .program-map .category-info svg {
  font-size: 10px!important;
    line-height: 14px;
    vertical-align: top;
}

.program-map .layer-name {
  float: left;
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.program-map .layer-info:focus {
  outline: none;
}

.program-map .add-btn {
  font-size: 12px;
  line-height: 16px;
  color: #0071bc;
  font-weight: 700;
  float: right;
  padding: 0;
  border: none;
  cursor: pointer;
}

.program-map .add-btn:hover, .program-map .add-btn:focus {
    color: #015d9a;
    background-color: white;
    border-color: white;
}

.program-map .remove-btn {
  font-size: 12px;
  line-height: 16px;
  color: #dc3545;
  font-weight: 700;
  float: right;
  padding: 0;
  border: none;
  cursor: pointer;
}

.program-map .remove-btn:hover, .program-map .remove-btn:focus {
  color: #ad1322;
  background-color: white;
  border-color: white;
}

.program-map #legendBtn {
  position: absolute;
  right: -29px;
  top: 20px;
  height: 50px;
  border: none;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  box-shadow: 1px 2px 4px 0 rgba(0,34,68,.22);
  padding: 12px 10px;
  cursor: pointer;
}

.program-map #programLegendBtn {
  position: absolute;
  right: 0;
  top: -29px;
  width: 50px;
  border: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  /*box-shadow: 4px 2px 0 2px rgba(0,34,68,.22);*/
  padding: 5px 12px;
  cursor: pointer;
}

.program-map #legendBtn:focus, .program-map .add-btn:focus, .program-map .remove-btn:focus, .program-map #programLegendBtn {
  outline: none;
}

.toggle-style.react-toggle--checked .react-toggle-track {
  background-color: transparent;
}

.toggle-style.react-toggle .react-toggle-track {
  background-color: transparent;
}

.toggle-style.react-toggle--checked .react-toggle-thumb {
  border-color: transparent;
  opacity: 0.62;
  margin-top: 2px;
  margin-left: 1px;
  width: 16px;
  height: 16px;
}

.toggle-style.react-toggle .react-toggle-thumb {
  border-color: transparent;
  opacity: 0.62;
  margin-top: 2px;
  margin-left: 1px;
  width: 16px;
  height: 16px;
}

.toggle-style.react-toggle--focus .react-toggle-thumb, .toggle-style.react-toggle:active:not(.react-toggle--disabled) .react-toggle-thumb {
  -webkit-box-shadow: 0px 0px 0px 0px #000000;
  -moz-box-shadow: 0px 0px 0px 0px #000000;
  box-shadow: 0px 0px 0px 0px #000000;
}

.toggle-style.react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: transparent;
}

.toggle-style.react-toggle--checked .react-toggle-track {
  background-color: transparent;
}

.toggle-style.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: transparent;
}

.toggle-style .react-toggle-track-check {
  top: 7px;
  bottom: 15px;
  left: 5px;
  color: #ffffff;
  font-family: 'Dubai-Regular', Lato, sans-serif;
  font-size: 10px;
  font-weight: 700;
  line-height: 16px;
  text-transform: uppercase;
}

.toggle-style .react-toggle-track-x {
  top: 7px;
  bottom: 15px;
  right: 17px;
  /*color: #c5cfdb;*/
  color: #fff;
  font-family: 'Dubai-Regular', Lato, sans-serif;
  font-size: 10px;
  font-weight: 700;
  line-height: 16px;
  text-transform: uppercase;
}

#stateColumn .dropdown-menu {
    height: 300px;
    overflow: hidden;
    overflow-y: auto;
  }

.spider-leg-container .spider-leg-pin {
      position: relative;
      width: 40px;
      height: 40px;
      margin-left: -20px;
      margin-top: -20px;
      font-size: 20px;
    }
    .spider-leg-container .spider-leg-pin i.type-icon {
      color: white;
    }
.spider-leg-container {
  width: 1px;
  height: 1px;
  overflow: display;
  will-change: transform;
}

.spider-leg-container:hover { cursor: pointer }

.spider-leg-container .spider-leg-pin {
  position: relative;
  z-index: 1;
}

.spider-leg-container .spider-leg-pin.default-spider-pin{
  position: relative;
  width: 25px;
  height: 41px;
  margin-left: -11.5px;
  margin-top: -38.5px;
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.0.0-beta.2/images/marker-icon.png);
}

.spider-leg-container .spider-leg-line {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 2px;
  background-color: #343434;
  opacity: 0.45;
  transform-origin: bottom;
  z-index: 0;
  height: 0;
}

.spider-leg-container:hover .spider-leg-line { opacity: 1 }

/* Animations specific styles */

.spider-leg-container.animate {
  -webkit-transition: margin 0.15s linear;
  -moz-transition: margin 0.15s linear;
  -ms-transition: margin 0.15s linear;
  -o-transition: margin 0.15s linear;
  transition: margin 0.15s linear;
}

.spider-leg-container.initial,
.spider-leg-container.exit {
  margin-left: 0 !important;
  margin-top: 0 !important;
  height: 0;
}

.spider-leg-container.animate .spider-leg-line {
  -webkit-transition: all 0.15s linear;
  -moz-transition: all 0.15s linear;
  -ms-transition: all 0.15s linear;
  -o-transition: all 0.15s linear;
  transition: all 0.15s linear;

  -webkit-transition-delay: inherit;
  -moz-transition-delay: inherit;
  -ms-transition-delay: inherit;
  -o-transition-delay: inherit;
  transition-delay: inherit;
}

.spider-leg-container.animate.initial .spider-leg-line,
.spider-leg-container.animate.exit .spider-leg-line { height: 0 !important }

.popover {
    white-space: pre-wrap;    
}

#swiper-wrapper .swiper-map {
  position: absolute;
  /*top: 0;*/
  /*bottom: 0;*/
  height: calc(100% - (60px));
  width: 100%;
}

@media (max-width: 768px) {

  #programMapContainer {
      padding: 0 !important;
  }

  #programMapContainer, #stateColumn {
      height: 400px;
  }

  #stateColumn {
      margin-top: 20px;
  }

  .program-map .program-legend {
    width: 320px;
  }

  .program-map #legend {
    left: -315px;
  }

  .program-map #legend.go-left {
    left: 0px;
  }
}