.wrapper {
  display: flex;
  justify-content: space-around;
  max-width: calc(100vw - 30px);
  grid-gap: 30px;
  /* margin-bottom: 60px; */
  margin-top: 32px;
  z-index: 10;
  position: relative;
}

.itemWrapper {
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 10;
}

.itemWrapper:not(:last-child)::after {
  content: '';
  display: block;
  position: absolute;
  z-index: -1;
  top: 18px;
  /* right: -30px; */
  /* right: -40px; */
  left: calc(50%);
  width: calc(100% + 48px);
  height: 3px;
  background: #002244;
}

.circle {
  background: #002244;
  width: 40px;
  height: 40px;
  padding: 8px;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-weight: 700;
  font-size: 16px;
  margin-bottom: 18px;
  font-family: 'Open Sans', sans-serif;
}

.circleInactive {
  background: rgb(244, 244, 244);
  border: 1px solid #002244;
  color: #002244;
}

.label {
  text-transform: uppercase;
  font-weight: 700;
  color: #787878 !important;
  text-align: center;
  margin-bottom: 8px;
  line-height: 21.79px;
  min-height: 40px;
}

.value {
  color: #787878;
  font-size: 16px;
  font-weight: 400;
  font-family: 'Open Sans', sans-serif;
}

@media screen and (max-width: 900px) {
  .wrapper {overflow-x: scroll;}
}

@media screen and (max-width: 768px) {
  .wrapper {
    grid-gap: 60px;
    justify-content: flex-start;
  }

  .itemWrapper {
    min-width: 160px;
  }
}