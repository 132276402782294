.header-container {
	width: 100%;
	height: 456px;
	padding-left: 120px;
	padding-right: 120px;
	background: #123D6A;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-end;
	color: #FFFFFF;
	padding-right: 50%;
	padding-bottom: 70px;
	background-position: right;
	background-size: contain;
	background-repeat: no-repeat;
}

@media (max-width: 850px) {
	.header-container {
		padding-left: 80px;
		padding-right: 80px;
	}
}

@media (max-width: 600px) {
	.header-container {
		padding-left: 20px !important;
		padding-right: 20px !important;
		background-position: center;
		text-align: center !important;
	}

	.header-container a, .header-container h2, .header-container span {
		text-align: center;
		margin-left: auto !important;
		margin-right: auto !important;
	}
}

@media (max-width: 400px) {
	.header-container {
		padding-left: 10px !important;
		padding-right: 10px !important;
		background-position: center;
	}
}

@media (max-width: 1100px) {
	.header-overlay {
		background-color: #123d6a9a;
		height: 100%;
		width: 100%;
		position: absolute;
		top: 0;
		left: 0;
	}

	.header-container {
		position: relative;
	}

	.header-container a, .header-container h2, .header-container span {
		z-index: 2;
	}
}

.header-title {
	font-family: 'Andes', sans-serif;
	font-size: 44px;
	line-height: 44px;
}

.header-desc {
	font-family: 'Open sans', sans-serif;
	font-weight: lighter;
	font-size: 28px;
	text-align: left;
	margin-bottom: 48px;
}