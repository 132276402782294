.modal-form {
    padding-bottom: 32px;
    border-bottom: 1px solid #E5E5E5;
    margin: 32px 0;
}

.modal-control {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.modal-control label {
    color: #4D4D4D;
    font-weight: 400;
}

.modal-control label span {
    color: #EB1C2D;
    margin-left: 5px;
}

.modal-control input:disabled {
    cursor: not-allowed;
}

.modal-row {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 20px;
}