.categoryContent.benchmark-section-content h2 {
  color: #1A1A1A !important;
  font-family: 'Andes', sans-serif;
  font-weight: 400;
  font-size: 32px;
  line-height: 32px;
}

.categoryContent.benchmark-section-content h4 {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 30px;
  color: #1A1A1A;
}

.categoryContent.benchmark-section-content p {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  color: #4D4D4D;
}