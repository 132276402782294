.graphTabs {
	background: #F2F2F2;
	padding: 15px 50px;
}

.graphTabs > ul > li {
	float: none;
	display: inline-block;
}

.graph-tabs-text {
	margin-right: 18px;
	font-size: 11px;
	font-weight: 400;
}

/*https://bootsnipp.com/snippets/featured/material-design-tab-style*/
.graphTabs > .nav-tabs { 
	border: none; 
	/*border: 1px solid transparent !important;*/
}
.graphTabs > .nav-tabs > li.active > a, .graphTabs > .nav-tabs > li.active > a:focus, .graphTabs > .nav-tabs > li.active > a:hover { 
	border: none; 
	border-width: 0;
	border-radius: 1px;
}

.graphTabs > .nav > li > a {
	padding: 1px 5px !important;
}

.graphTabs > .nav-tabs > li.active > a, .graphTabs > .nav-tabs > li > a:hover { 
	background-color: #cd2c0f;
	padding: 1px 5px !important;
	border: none;
	/*margin: 1px 2px;*/
}

.graphTabs > .nav-tabs > li.active > a > .tabName, .graphTabs > .nav-tabs > li > a:hover > .tabName { 
	color: #fff;
}

.graphTabs > .nav > li > a:hover, .graphTabs > .nav > li > a:focus {
	background-color: #cd2c0f !important;
}

.graphTabs > .tab-pane { 
	border: none; 
	margin: 0;
  	padding: 0;
}

.graphTabs > .tab-content{
	border: none; 
	padding: 0;
	padding-top: 3px;
	margin: 0;
}

.graphTabs > ul > li > a > .tabName {
	font-size: 11px;
	font-weight: 700;
	font-family: 'Open Sans', sans-serif;
	margin: 4px;
}

.graphTabs > .nav-tabs > li > a {
	border-radius: 1px !important;
}
